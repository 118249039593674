// src/pages/ProductForm.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getProduct, createProduct, updateProduct } from '../api/ProductService';
import { toast } from 'react-toastify';
import Header from '../components/Header';
import { FaCheck, FaTimes } from 'react-icons/fa';

const Container = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.background};
  min-height: 100vh;
  font-family: ${({ theme }) => theme.fonts.regular};
  
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Form = styled.form`
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 20px;
  max-width: 500px;
  margin: 40px auto;
  box-shadow: 0 2px 5px ${({ theme }) => theme.colors.shadow};
`;

const FormTitle = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 5px;
  font-size: 16px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 12px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  font-size: 16px;
  background-color: ${({ theme }) => theme.colors.inputBackground};
  color: ${({ theme }) => theme.colors.text};
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.primary};
  }
`;

const SwitchGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const SwitchLabel = styled.span`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  span {
    position: absolute;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 34px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 17px;
      width: 17px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      border-radius: 50%;
      transition: 0.4s;
    }
  }
  input:checked + span {
    background-color: #007AFF;
  }
  input:checked + span:before {
    transform: translateX(24px);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const Button = styled.button`
  background-color: ${({ color, theme }) => color || theme.colors.buttonBackground};
  color: ${({ color, theme }) => (color ? '#FFFFFF' : theme.colors.buttonText)};
  border: none;
  border-radius: 8px;
  padding: 10px 18px;
  cursor: pointer;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.regular};
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.2s ease, opacity 0.2s ease;
  
  &:hover {
    opacity: 0.95;
  }
`;

const CancelButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.text};
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.inputBackground};
  }
`;

const ProductForm = () => {
  const { id } = useParams(); // Récupérer l'id du produit s'il existe
  const isEditing = Boolean(id);
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    commissionAmount: '', // Montant de la commission
  });

  const [hasCommission, setHasCommission] = useState(false);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (isEditing) {
      fetchProduct(id);
    }
  }, [id, isEditing]);
  
  const fetchProduct = async (productId) => {
    setLoading(true);
    try {
      const product = await getProduct(productId);
      setFormData({
        name: product.name || '',
        price: product.price !== undefined ? product.price.toString() : '',
        commissionAmount: product.commissionAmount !== undefined ? product.commissionAmount.toString() : '',
      });
      setHasCommission(!!product.commissionAmount);
    } catch (error) {
      console.error('Erreur lors de la récupération du produit:', error);
      toast.error('Erreur lors de la récupération du produit.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleToggleCommission = () => {
    setHasCommission(!hasCommission);
    if (hasCommission) {
      // Réinitialiser le montant de la commission si on désactive
      setFormData((prevData) => ({
        ...prevData,
        commissionAmount: '',
      }));
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validation simple
    if (!formData.name.trim()) {
      toast.error('Le nom du produit est requis.');
      return;
    }
    
    if (!formData.price || isNaN(formData.price)) {
      toast.error('Le prix doit être un nombre valide.');
      return;
    }

    if (hasCommission && (!formData.commissionAmount || isNaN(formData.commissionAmount))) {
      toast.error('Le montant de la commission doit être un nombre valide.');
      return;
    }
    
    const productPayload = {
      name: formData.name.trim(),
      price: parseFloat(formData.price),
      ...(hasCommission && { commissionAmount: parseFloat(formData.commissionAmount) }),
    };
    
    try {
      setLoading(true);
      if (isEditing) {
        await updateProduct(id, productPayload);
        toast.success('Produit mis à jour avec succès.');
      } else {
        await createProduct(productPayload);
        toast.success('Produit créé avec succès.');
      }
      navigate('/');
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire:', error);
      toast.error('Erreur lors de la soumission du formulaire.');
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Container>
      <Header title={isEditing ? 'Modifier Produit' : 'Ajouter Produit'} />
      <Form onSubmit={handleSubmit}>
        <FormTitle>{isEditing ? 'Modifier Produit' : 'Ajouter Nouveau Produit'}</FormTitle>
        <FormGroup>
          <Label htmlFor="name">Nom du Produit</Label>
          <Input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Entrez le nom du produit"
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="price">Prix (€)</Label>
          <Input
            type="number"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleChange}
            placeholder="Entrez le prix du produit"
            step="0.01"
            min="0"
            required
          />
        </FormGroup>
        
        {/* Interrupteur pour le montant de la commission */}
        <SwitchGroup>
          <ToggleSwitch>
            <input
              type="checkbox"
              id="hasCommission"
              checked={hasCommission}
              onChange={handleToggleCommission}
            />
            <span></span>
          </ToggleSwitch>
          <SwitchLabel>Ajouter un montant de commission</SwitchLabel>
        </SwitchGroup>

        {hasCommission && (
          <FormGroup>
            <Label htmlFor="commissionAmount">Montant de la Commission (€)</Label>
            <Input
              type="number"
              id="commissionAmount"
              name="commissionAmount"
              value={formData.commissionAmount}
              onChange={handleChange}
              placeholder="Entrez le montant de la commission"
              step="0.01"
              min="0"
              required
            />
          </FormGroup>
        )}
        
        <ButtonGroup>
          <CancelButton type="button" onClick={() => navigate('/')}>
            <FaTimes /> Annuler
          </CancelButton>
          <Button type="submit" color="#007AFF">
            <FaCheck /> {isEditing ? 'Mettre à Jour' : 'Créer'}
          </Button>
        </ButtonGroup>
      </Form>
    </Container>
  );
};

export default ProductForm;
