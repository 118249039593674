// src/api/ProductService.js
import api from '../axiosConfig';

// Récupérer la liste des produits
export const getUsers = async () => {
  try {
    const response = await api.get('/api/users');
    // Adapter selon la structure des données
    return response.data['hydra:member'] || response.data;
  } catch (error) {
    console.error('Erreur dans getusers:', error);
    throw error;
  }
};

// Récupérer un seul utilisateur
export const getUser = async (id) => {
  try {
    const response = await api.get(`/api/users/${id}`);
    return response.data;
  } catch (error) {
    console.error('Erreur dans getUser:', error);
    throw error;
  }
};

// Créer un nouveau utilisateur
export const createUser = async (userData) => {
  try {
    // Adapter les données au format JSON-LD
    const jsonLdData = {
      "@context": "/api/contexts/User",
      "@type": "User",
      ...userData,
    };

    const response = await api.post('/api/users', jsonLdData, {
      headers: {
        'Content-Type': 'application/ld+json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Erreur dans createUser:', error);
    throw error;
  }
};

// Mettre à jour un utilisateur existant
export const updateUser = async (id, userData) => {
  try {
    // Adapter les données au format JSON-LD
    const jsonLdData = {
      "@context": `/api/contexts/User/${id}`,
      "@type": "User",
      ...userData,
    };

    // Utilisez PATCH et le bon type MIME "application/merge-patch+json"
    const response = await api.patch(`/api/users/${id}`, jsonLdData, {
      headers: {
        'Content-Type': 'application/merge-patch+json', // Le type MIME attendu par l'API
      },
    });
    return response.data;
  } catch (error) {
    console.error('Erreur dans updateUser:', error);
    throw error;
  }
};


// Supprimer un produit
export const deleteUser = async (id) => {
  try {
    await api.delete(`/api/users/${id}`);
  } catch (error) {
    console.error('Erreur dans deleteUser:', error);
    throw error;
  }
};
