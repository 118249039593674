import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import { FaStar, FaBell, FaHeart, FaEnvelope, FaCog, FaUser, FaChartBar } from 'react-icons/fa';
import { Logout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'; // Import pour rediriger l'utilisateur

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
`;

const MenuContainer = styled(motion.div)`
  position: absolute;
  background-color: white;
  border-radius: 15px;
  padding: 10px 14px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 150px;
  max-height: 200px; /* Limite la hauteur du menu */
  overflow-y: auto;  /* Permet le défilement interne si le menu est trop grand */
`;

const MenuItem = styled(motion.button)`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: #333;

  &:hover {
    color: #007AFF;
  }

  svg {
    margin-right: 10px;
  }
`;

const menuVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.8 },
};

const overlayVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};

// Fonction de déconnexion
const handleLogout = (navigate) => {
  // Supprimer le token du localStorage
  localStorage.removeItem('jwtToken');

  // Rediriger vers la page de connexion
  navigate('/login');
};

// Définir les menus spécifiques
const menus = (navigate) => ({
  Home: [
    { icon: <FaStar />, label: 'Favoris', onClick: () => alert('Favoris sélectionné') },
    { icon: <FaBell />, label: 'Notifications', onClick: () => alert('Notifications sélectionnées') },
    { icon: <FaHeart />, label: 'Likes', onClick: () => alert('Likes sélectionnées') },
  ],
  Dashboard: [
    { icon: <FaChartBar />, label: 'Statistiques', onClick: () => alert('Statistiques sélectionnées') },
    { icon: <FaCog />, label: 'Paramètres du Dashboard', onClick: () => alert('Paramètres Dashboard sélectionnés') },
  ],
  Profile: [
    { icon: <FaUser />, label: 'Modifier Profil', onClick: () => alert('Modifier Profil sélectionné') },
    { icon: <FaEnvelope />, label: 'Messages', onClick: () => alert('Messages sélectionnés') },
  ],
  Messages: [
    { icon: <FaEnvelope />, label: 'Envoyer Message', onClick: () => alert('Envoyer Message sélectionné') },
    { icon: <FaHeart />, label: 'Favoris', onClick: () => alert('Favoris sélectionné') },
    { icon: <FaBell />, label: 'Notifications', onClick: () => alert('Notifications sélectionnées') },
  ],
  Settings: [
    { icon: <Logout />, label: 'Déconnexion', onClick: () => handleLogout(navigate) }, // Appel de la fonction de déconnexion
    { icon: <FaUser />, label: 'Compte', onClick: () => alert('Compte sélectionné') },
  ],
});

const IOSMenu = ({ position, onClose, menuType, menuRef }) => {
  const navigate = useNavigate(); // Hook pour rediriger l'utilisateur
  const currentMenuItems = menus(navigate)[menuType] || [];

  return (
    <AnimatePresence>
      <Overlay
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={overlayVariants}
        transition={{ duration: 0.2 }}
        onClick={onClose}
      />
      <MenuContainer
        ref={menuRef}
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={menuVariants}
        transition={{ type: 'spring', stiffness: 300, damping: 20 }}
        style={{ top: position.y, left: position.x, zIndex: 99999 }}
      >
        {currentMenuItems.map((item, index) => (
          <MenuItem
            key={index}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              item.onClick();
              onClose();
            }}
          >
            {item.icon}
            {item.label}
          </MenuItem>
        ))}
      </MenuContainer>
    </AnimatePresence>
  );
};

IOSMenu.propTypes = {
  position: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  menuType: PropTypes.string.isRequired,
  menuRef: PropTypes.object,
};

export default IOSMenu;
