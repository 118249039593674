// src/api/TransactionService.js

import api from '../axiosConfig';

// Récupérer la liste des transactions
export const getTransactions = async () => {
  try {
    const response = await api.get('/api/transactions');
    // Extraire le tableau des transactions depuis 'hydra:member' ou 'member'
    return response.data['hydra:member'] || response.data.member || [];
  } catch (error) {
    console.error('Erreur lors de la récupération des transactions:', error);
    throw error; // Propager l'erreur pour la gérer dans le composant
  }
};

// Récupérer une seule transaction
export const getTransaction = async (id) => {
  try {
    const response = await api.get(`/api/transactions/${id}`);
    return response.data;
  } catch (error) {
    console.error('Erreur dans getTransaction:', error);
    throw error;
  }
};

// Créer une nouvelle transaction
export const createTransaction = async (transactionData) => {
  try {
    // Adapter les données au format JSON-LD
    const jsonLdData = {
      "@context": "/api/contexts/Transaction",
      "@type": "Transaction",
      ...transactionData,
    };

    const response = await api.post('/api/transactions', jsonLdData, {
      headers: {
        'Content-Type': 'application/ld+json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Erreur dans createTransaction:', error);
    throw error;
  }
};

// Mettre à jour une transaction existante
export const updateTransaction = async (id, transactionData) => {
  try {
    // Adapter les données au format JSON-LD
    const jsonLdData = {
      "@context": `/api/contexts/Transaction/${id}`,
      "@type": "Transaction",
      ...transactionData,
    };

    // Utiliser PATCH avec le bon type MIME
    const response = await api.patch(`/api/transactions/${id}`, jsonLdData, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Erreur dans updateTransaction:', error);
    throw error;
  }
};

// Supprimer une transaction
export const deleteTransaction = async (id) => {
  try {
    await api.delete(`/api/transactions/${id}`);
  } catch (error) {
    console.error('Erreur dans deleteTransaction:', error);
    throw error;
  }
};
