import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2'; // Chart.js pour les graphiques
import 'chart.js/auto';
import Card from '../components/Card'; // Importation des cartes minutieuses
import api from '../axiosConfig'; // Pour récupérer les données
import { useNavigate } from 'react-router-dom'; // Pour la navigation
import { toast } from 'react-toastify';

// Conteneur général
const Container = styled.div`
  padding: 20px 20px 20px 35px;
  background-color: #F2F2F7;
  height: 100vh;
  overflow-y: scroll;
`;

// Titre attrayant
const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
  color: #1C1C1E;
  margin-bottom: 30px;
  text-align: center;
`;

// Section des graphes
const GraphSection = styled.div`
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
`;

// Section des cartes
const Section = styled.div`
  margin-bottom: 30px;
`;

// Chevron pour les sections
const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
`;

const SectionTitle = styled.h3`
  font-size: 20px;
  color: #1C1C1E;
  font-weight: 600;
`;

const Chevron = styled.span`
  color: #C7C7CC;
  font-size: 18px;
`;

// Paramètres - Bouton iOS
const SettingsButton = styled.button`
  background-color: #007AFF;
  color: white;
  border: none;
  border-radius: 16px;
  padding: 12px 20px;
  font-size: 17px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #005BBB;
  }

  &:active {
    background-color: #003E8A;
  }
`;

// Données fictives pour les graphiques
const chartData = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
  datasets: [
    {
      label: 'Transactions',
      data: [500, 800, 400, 900, 1000, 1200, 1300],
      borderColor: '#007AFF',
      backgroundColor: 'rgba(0, 122, 255, 0.1)',
      fill: true,
      tension: 0.4,
    },
  ],
};

// Composant Dashboard
const Dashboard = () => {
  const [transactions, setTransactions] = useState([]); // Initialisation en tant que tableau
  const [credits, setCredits] = useState([]);
  const [stocks, setStocks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Appel à l'API pour récupérer les transactions, crédits, et stocks
    const fetchData = async () => {
      try {
        const transactionsResponse = await api.get('/api/transactions');
        setTransactions(Array.isArray(transactionsResponse.data) ? transactionsResponse.data : []);

        const creditsResponse = await api.get('/api/credits');
        setCredits(Array.isArray(creditsResponse.data) ? creditsResponse.data : []);

        const stocksResponse = await api.get('/api/stocks');
        setStocks(Array.isArray(stocksResponse.data) ? stocksResponse.data : []);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
        toast.error('Erreur lors de la récupération des données.');
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      <Title>Tableau de Bord</Title>

      {/* Section Graphiques */}
      <GraphSection style={{ margin: 'auto' }}>
        <SectionHeader>
          <SectionTitle>Évolution des transactions</SectionTitle>
          <Chevron>›</Chevron>
        </SectionHeader>
        <Line data={chartData} />
      </GraphSection>

      {/* Cartes avec détails */}
      <Section>
        <SectionHeader>
          <SectionTitle>Transactions récentes</SectionTitle>
          <Chevron>›</Chevron>
        </SectionHeader>
        {transactions.map(transaction => (
          <Card
            key={transaction.id}
            title={`Transaction: ${transaction.amount}€`}
            detail={`Date: ${new Date(transaction.date).toLocaleDateString()}`}
          />
        ))}
      </Section>

      <Section>
        <SectionHeader>
          <SectionTitle>Crédits</SectionTitle>
          <Chevron>›</Chevron>
        </SectionHeader>
        {credits.map(credit => (
          <Card
            key={credit.id}
            title={`Crédit: ${credit.amount}€`}
            detail={`Échéance: ${new Date(credit.dueDate).toLocaleDateString()}`}
          />
        ))}
      </Section>

      <Section>
        <SectionHeader>
          <SectionTitle>Stocks</SectionTitle>
          <Chevron>›</Chevron>
        </SectionHeader>
        {stocks.map(stock => (
          <Card
            key={stock.id}
            title={`Stock: ${stock.name}`}
            detail={`Quantité: ${stock.quantity}`}
          />
        ))}
      </Section>

      {/* Bouton Paramètres */}
      <SettingsButton onClick={() => navigate('/settings')}>Accéder aux paramètres</SettingsButton>
    </Container>
  );
};

export default Dashboard;