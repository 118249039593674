// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const PrivateRoute = ({ children, allowedRoles }) => {
  const { auth } = useAuth(); // Récupérer le contexte d'authentification
  const { user } = auth;

  if (!user) {
    return <Navigate to="/login" />; // Redirige vers la page de connexion si l'utilisateur n'est pas authentifié
  }

  if (allowedRoles && !allowedRoles.some(role => user.roles.includes(role))) {
    return <Navigate to="/" />; // Redirige si l'utilisateur n'a pas le rôle approprié
  }

  return children; // Retourne les enfants si tout est bon
};

export default PrivateRoute;
