import React, { useState } from 'react';
import styled from 'styled-components';
import api from '../axiosConfig'; // Import de la configuration Axios pour l'API
import { toast } from 'react-toastify';

const LockScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f2f2f7;
`;

const PinInput = styled.input`
  width: 200px;
  padding: 15px;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  outline: none;
`;

const UnlockButton = styled.button`
  padding: 15px 30px;
  font-size: 18px;
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #005bb5;
  }
`;

const LockScreen = ({ onUnlock }) => {
  const [pin, setPin] = useState('');

  const handleUnlock = async () => {
    if (pin.length !== 6) {
      toast.error('Le PIN doit contenir 6 chiffres');
      return;
    }

    try {
      const response = await api.post('/api/verify-pin', { pin });
      if (response.data.success) {
        toast.success('PIN correct, déverrouillage...');
        onUnlock(); // Appelle la fonction pour déverrouiller l'application
      }
    } catch (error) {
      toast.error('PIN incorrect, veuillez réessayer');
    }
  };

  return (
    <LockScreenContainer>
      <h2>Entrez votre PIN</h2>
      <PinInput 
        type="password" 
        value={pin} 
        onChange={(e) => setPin(e.target.value)} 
        maxLength={6}
        placeholder="******"
      />
      <UnlockButton onClick={handleUnlock}>Déverrouiller</UnlockButton>
    </LockScreenContainer>
  );
};

export default LockScreen;
