import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styled, { keyframes } from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { getProducts, updateProduct } from '../api/ProductService';
import { createCredit, updateCredit } from '../api/CreditService';
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/AuthContext';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
`;

const StyledOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  transition: opacity 300ms ease-in-out;
  animation: ${fadeIn} 0.3s ease-out forwards;
  &.ReactModal__Overlay--before-close {
    animation: ${fadeOut} 0.3s ease-out forwards;
  }
`;

const StyledContent = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  max-width: 80%;
  margin: auto;
  width: 50vh;
  text-align: center;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  position: relative;
  animation: ${fadeIn} 0.3s ease-out forwards;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: #8e8e93;
  cursor: pointer;
  font-size: 24px;
  &:hover {
    color: #007AFF;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const FormTitle = styled.h2`
  color: #007AFF;
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
  text-align: left;
`;

const Label = styled.label`
  font-size: 16px;
  color: #1c1c1e;
  margin-bottom: 8px;
  display: block;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  background-color: #f2f2f7;
  border: 1px solid #d1d1d6;
  border-radius: 12px;
  color: #1c1c1e;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 25px;
`;

const Button = styled.button`
  flex: 1;
  padding: 14px 20px;
  border-radius: 10px;
  font-size: 16px;
  background-color: ${({ color }) => color || '#007AFF'};
  color: white;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #005bb5;
  }
`;

const CancelButton = styled(Button)`
  background-color: #f2f2f7;
  color: #1c1c1e;
  &:hover {
    background-color: #e5e5ea;
  }
`;

const CreditModal = ({ isOpen, onRequestClose, onSubmit, creditData, userId }) => {
  const [formData, setFormData] = useState({
    title: '',
    totalAmount: '',
    quantity: '1',
    status: '',
    commissionAmount: '',
    product: '',
  });

  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const { auth } = useAuth();
  const { user } = auth || {};
  const authorizedCategories = user?.authorizedCategories || [];

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getProducts();
        const productsList = data.member || [];

        if (user.roles.includes('ROLE_ADMIN')) {
          setProducts(productsList);
        } else {
          const authorizedProducts = productsList.filter(product => {
            if (!product.category || !product.category["@id"]) {
              console.warn(`Produit ${product.name} sans catégorie ou ID de catégorie`);
              return false;
            }

            const extractedCategoryId = product.category["@id"].split('/').pop();
            return authorizedCategories.some(category => 
              String(category.id) === String(extractedCategoryId)
            );
          });

          setProducts(authorizedProducts); 
        }

        setLoadingProducts(false);
      } catch (error) {
        console.error('Erreur lors du chargement des produits:', error);
        toast.error('Erreur lors du chargement des produits');
        setLoadingProducts(false);
      }
    };
    fetchProducts();
  }, [authorizedCategories, user]);

  useEffect(() => {
    if (creditData) {
      setFormData({
        title: creditData.title || '',
        totalAmount: creditData.totalAmount || '',
        quantity: creditData.quantity || '1',
        status: creditData.status || '',
        commissionAmount: creditData.commissionAmount || '',
        product: creditData.product ? String(creditData.product.id) : '',
      });
    } else {
      setFormData({
        title: '',
        totalAmount: '',
        quantity: '1',
        status: '',
        commissionAmount: '',
        product: '',
      });
    }
  }, [creditData]);

  useEffect(() => {
    if (formData.product) {
      const selectedProduct = products.find((product) => product.id === parseInt(formData.product, 10));

      if (selectedProduct) {
        const totalAmount = parseFloat(selectedProduct.price) * parseInt(formData.quantity, 10);
        const commission = parseFloat(selectedProduct.commissionAmount) * parseInt(formData.quantity, 10);

        setFormData((prevData) => ({
          ...prevData,
          totalAmount: totalAmount.toFixed(2),
          commissionAmount: commission.toFixed(2),
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          totalAmount: '',
          commissionAmount: '',
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        totalAmount: '',
        commissionAmount: '',
      }));
    }
  }, [formData.product, formData.quantity, products]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userId) {
      toast.error('Utilisateur non identifié.');
      return;
    }

    if (!formData.product) {
      toast.error('Veuillez sélectionner un produit.');
      return;
    }

    const selectedProduct = products.find((product) => product.id === parseInt(formData.product, 10));
    if (!selectedProduct) {
      toast.error('Produit introuvable.');
      return;
    }

    const requestedQuantity = parseInt(formData.quantity, 10);
    const currentStock = parseInt(selectedProduct.stockQuantity, 10);

    let updatedStockQuantity;
    if (creditData) {
      // Si on modifie un crédit existant
      const originalQuantity = parseInt(creditData.quantity, 10);
      updatedStockQuantity = currentStock + originalQuantity - requestedQuantity;

      if (updatedStockQuantity < 0) {
        toast.error('La quantité demandée dépasse le stock disponible.');
        return;
      }
    } else {
      // Si on crée un nouveau crédit
      updatedStockQuantity = currentStock - requestedQuantity;

      if (updatedStockQuantity < 0) {
        toast.error('La quantité demandée dépasse le stock disponible.');
        return;
      }
    }

    // Mettre à jour le stock du produit
    const productUpdatePayload = {
      stockQuantity: updatedStockQuantity.toString(),
    };

    try {
      await updateProduct(selectedProduct.id, productUpdatePayload);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du stock :', error);
      toast.error('Erreur lors de la mise à jour du stock.');
      return;
    }

    const creditPayload = {
      ...formData,
      user: `/api/users/${userId}`,
      product: `/api/products/${formData.product}`,
    };

    try {
      if (creditData) {
        await updateCredit(creditData.id, creditPayload);
        toast.success('Crédit mis à jour avec succès');
      } else {
        await createCredit(creditPayload);
        toast.success('Crédit ajouté avec succès');
      }
      onRequestClose();
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement du crédit :', error);
      toast.error('Erreur lors de l\'enregistrement du crédit');
    }
  };

  const getCommissionAmount = (amount) => (isNaN(amount) ? 0 : amount);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      contentElement={(props, children) => <StyledContent {...props}>{children}</StyledContent>}
      overlayElement={(props, contentElement) => <StyledOverlay {...props}>{contentElement}</StyledOverlay>}
    >
      <CloseButton onClick={onRequestClose}>
        <FaTimes />
      </CloseButton>
      <Form onSubmit={handleSubmit}>
        <FormTitle>{creditData ? 'Modifier le crédit' : 'Nouveau crédit'}</FormTitle>
        <FormGroup>
          <Label htmlFor="title">Titre</Label>
          <Input name="title" value={formData.title} onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="product">Produit</Label>
          <Input as="select" name="product" value={formData.product} onChange={handleChange} required disabled={loadingProducts}>
            <option value="">Sélectionnez un produit</option>
            {products.map(product => (
              <option key={product.id} value={product.id}>
                {product.name} - Prix: {product.price} € - Commission: {getCommissionAmount(product.commissionAmount)} € - Stock: {product.stockQuantity}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="quantity">Quantité</Label>
          <Input type="number" name="quantity" min="1" value={formData.quantity} onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="totalAmount">Montant Total</Label>
          <Input type="number" name="totalAmount" value={formData.totalAmount} readOnly />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="status">Statut</Label>
          <Input as="select" name="status" value={formData.status} onChange={handleChange} required>
            <option value="">Choisissez un statut</option>
            <option value="Pending">En attente</option>
            <option value="Completed">Complété</option>
            <option value="Cancelled">Annulé</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="commissionAmount">Montant de la commission</Label>
          <Input type="number" name="commissionAmount" value={formData.commissionAmount} readOnly />
        </FormGroup>
        <ButtonGroup>
          <CancelButton type="button" onClick={onRequestClose}>Annuler</CancelButton>
          <Button type="submit">{creditData ? 'Mettre à jour' : 'Ajouter'}</Button>
        </ButtonGroup>
      </Form>
    </Modal>
  );
};

CreditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  creditData: PropTypes.object,
  userId: PropTypes.number.isRequired,
};

export default CreditModal;
