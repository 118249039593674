// src/api/ProductService.js
import api from '../axiosConfig';

// Récupérer la liste des produits
export const getProducts = async () => {
  try {
    const response = await api.get('/api/products');
    // Adapter selon la structure des données
    return response.data['hydra:member'] || response.data;
  } catch (error) {
    console.error('Erreur dans getProducts:', error);
    throw error;
  }
};

// Récupérer un seul produit
export const getProduct = async (id) => {
  try {
    const response = await api.get(`/api/products/${id}`);
    return response.data;
  } catch (error) {
    console.error('Erreur dans getProduct:', error);
    throw error;
  }
};

// Créer un nouveau produit
export const createProduct = async (productData) => {
  try {
    // Adapter les données au format JSON-LD
    const jsonLdData = {
      "@context": "/api/contexts/Product",
      "@type": "Product",
      ...productData,
    };

    const response = await api.post('/api/products', jsonLdData, {
      headers: {
        'Content-Type': 'application/ld+json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Erreur dans createProduct:', error);
    throw error;
  }
};

// Mettre à jour un produit existant
export const updateProduct = async (id, productData) => {
  try {
    // Adapter les données au format JSON-LD
    const jsonLdData = {
      "@context": `/api/contexts/Product/${id}`,
      "@type": "Product",
      ...productData,
    };

    // Utilisez PATCH et le bon type MIME "application/merge-patch+json"
    const response = await api.patch(`/api/products/${id}`, jsonLdData, {
      headers: {
        'Content-Type': 'application/merge-patch+json', // Le type MIME attendu par l'API
      },
    });
    return response.data;
  } catch (error) {
    console.error('Erreur dans updateProduct:', error);
    throw error;
  }
};


// Supprimer un produit
export const deleteProduct = async (id) => {
  try {
    await api.delete(`/api/products/${id}`);
  } catch (error) {
    console.error('Erreur dans deleteProduct:', error);
    throw error;
  }
};
