// src/components/TransactionList.jsx

import React, { useContext, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import {
  getTransactions,
  deleteTransaction,
  createTransaction,
  updateTransaction,
} from '../api/TransactionService';
import {
  FaPen,
  FaTrash,
  FaPlus,
  FaBoxOpen,
  FaCopy,
  FaCalendarAlt,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import Header from '../components/Header';
import TransactionModal from '../components/TransactionModal';
import ConfirmModal from '../components/ConfirmModal';
import { IonSpinner } from '@ionic/react';
import { AuthContext, useAuth } from '../contexts/AuthContext';
import axios from '../axiosConfig';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Slider from 'react-slick';

// Importation des styles de react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { GrTransaction } from 'react-icons/gr';
import { updateProduct } from '../api/ProductService';
import { Pagination } from '@mui/material';

// Styles (iOS-like)
const Container = styled.div`
  padding: 20px;
  background-color: #f7f7f7;
  min-height: 100vh;
  font-family: 'San Francisco', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  padding-bottom: 20%; /* Ajoute de l'espace en bas */
`;

// Grid and list view containers
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  padding-bottom: 20%; /* Ajoute de l'espace en bas */
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

// Empty state
const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  color: #888;
  font-size: 18px;
  text-align: center;
`;

const EmptyIcon = styled(FaBoxOpen)`
  font-size: 50px;
  margin-bottom: 15px;
  color: #ccc;
`;
const TransactionIcon = styled(GrTransaction)`
  font-size: 50px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  color: #ccc;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const PaginationButton = styled.button`
  background-color: ${({ active }) => (active ? '#007aff' : '#fff')};
  color: ${({ active }) => (active ? '#fff' : '#007aff')};
  border: 1px solid #007aff;
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;


// Transaction card for grid view
const TransactionCard = styled.div`
  background-color: #fff;
  padding: 15px 10px;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  margin-bottom: 15px; /* Ajout de marge pour espacer les cartes */

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
`;

// Information inside each transaction card
const TransactionInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const TransactionAmount = styled.h3`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
`;

const TransactionTitle = styled.h2`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const TransactionDate = styled.p`
  font-size: 12px;
  color: #666;
  position: absolute;
  right: 15px;
  bottom: 15px;
`;

// Action buttons (edit/delete/copy)
const ActionButtonGroup = styled.div`
  display: flex;
  gap: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

// ActionButton stylisé
const ActionButton = styled.button`
  background-color: ${({ color }) => color};
  color: #fff;
  border: none;
  padding: 8px;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;

  &:hover {
    background-color: ${({ hoverColor }) => hoverColor};
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

// Add transaction button
const AddButton = styled.button`
  position: fixed;
  bottom: 60px;
  right: 20px;
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #005bb5;
    transform: scale(1.05);
  }
`;

// Rapports Administratifs (Nouveau Composant)
const ReportsContainerStyled = styled.div`
  margin-top: 40px;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

// Rapport Section Stylisé
const ReportSectionStyled = styled.div`
  background-color: #f2f2f7;
  padding: 15px;
  margin: 15px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ReportItem = styled.p`
  font-size: 14px;
  margin: 5px 0;
`;

// Titre pour les rapports
const ReportTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

// Rapport Header avec Icône de Calendrier
const ReportHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterButton = styled.button`
  background-color: ${({ active }) => (active ? '#007aff' : '#f1f1f1')};
  color: ${({ active }) => (active ? '#fff' : '#007aff')};
  border: none;
  border-radius: 15px;
  padding: 2vw 4vw;
  font-size: 14px; /* Réduisez la taille de la police */
  font-weight: 500;
  box-shadow: ${({ active }) => (active ? '0 4px 8px rgba(0, 0, 0, 0.2)' : '0 2px 4px rgba(0, 0, 0, 0.1)')};
  transition: all 0.3s ease;
  margin: 5px;
  cursor: pointer;
  min-width: 20vw; /* Largeur minimale pour les petits écrans */

  &:hover {
    background-color: ${({ active }) => (active ? '#005bb5' : '#e0e0e0')};
  }

  &:active {
    transform: scale(0.98);
  }
`;

const FilterButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Permet aux boutons de se répartir sur plusieurs lignes */
  justify-content: center;
  margin: 2vh 0;
`;

// **Paramètres du slider**
const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
};

// Composant TransactionList
const TransactionList = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [editingTransactionId, setEditingTransactionId] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [transactionToDelete, setTransactionToDelete] = useState(null);
  const [viewMode, setViewMode] = useState('grid');
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const handleStatusChange = (status) => setStatusFilter(status);

  // Filtres par date
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateFilterMode, setDateFilterMode] = useState('range');

  const { auth, setAuth } = useAuth(); // Utilisation de setAuth ici
  const { user } = auth || {};
  const authorizedCategories = user?.authorizedCategories || [];
  // Obtenir les catégories autorisées de l'utilisateur
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 20; // Nombre d'éléments par page (peut être ajusté)
  
  // Fetching transactions
  const fetchTransactions = useCallback(async () => {
    try {
      const response = await axios.get('/api/transactions', {
        params: {
          Pagination:false,
          populate: ['product', 'product.category', 'user'],
        },
      });
  
      // Selon la structure de votre API, vous devrez peut-être ajuster ces lignes
      const data = response.data['hydra:member'] || response.data.member || [];
      setTransactions(data);
      console.log(data)
  
      // Récupérer le nombre total d'éléments et calculer le nombre total de pages
      const totalItems =
        response.data['hydra:totalItems'] || response.data.totalItems || 0;
      const calculatedTotalPages = Math.ceil(totalItems / itemsPerPage);
      setTotalPages(calculatedTotalPages);
      setTotalItems(totalItems)
  
    } catch (error) {
      console.error('Erreur lors de la récupération des transactions:', error);
    } finally {
      setLoading(false);
    }
  }, [currentPage]);
  

  // Déterminer si l'utilisateur est administrateur
  const isAdmin = user && user.roles && user.roles.includes('ROLE_ADMIN');

  // À l'intérieur de useEffect pour le polling :
  useEffect(() => {
    console.log('Rôles de l\'utilisateur :', user?.roles);

    const pollCategories = async () => {
      try {
        const response = await axios.get('/check');
        setAuth((prevAuth) => ({
          ...prevAuth,
          user: {
            ...prevAuth.user,
            authorizedCategories: response.data.authorizedCategories,
          },
        }));
        // Met à jour les transactions après avoir mis à jour les catégories autorisées
        fetchTransactions();
      } catch (error) {
        console.error('Erreur lors du polling des catégories :', error);
      }
    };

    // Effectuer une interrogation toutes les 5 secondes pour vérifier les catégories mises à jour
    const interval = setInterval(pollCategories, 5000);

    // Nettoyer l'intervalle lors du démontage du composant
    return () => clearInterval(interval);
  }, [setAuth, fetchTransactions]); // Ajoute `fetchTransactions` comme dépendance

  const handleTransactionSubmit = async (transactionData) => {
    try {
      // Ajouter l'IRI de l'utilisateur si elle n'est pas déjà incluse
      if (!transactionData.user && user?.id) {
        transactionData.user = `/api/users/${user.id}`;
      }

      // Ne pas modifier 'user' et 'product', ils sont déjà définis dans transactionData
      const formattedData = {
        ...transactionData,
        title: String(transactionData.title),
        totalAmount: String(transactionData.totalAmount),
        commissionAmount: String(transactionData.commissionAmount),
      };

      if (editingTransactionId) {
        // Modification d'une transaction existante sans ajuster le stock
        await updateTransaction(editingTransactionId, formattedData);
        toast.success('Transaction mise à jour avec succès.');
      } else {
        // Création d'une nouvelle transaction avec ajustement du stock
        await createTransaction(formattedData);
        toast.success('Transaction créée avec succès.');
      }

      closeTransactionModal(); // Fermer la modal après succès
      fetchTransactions(); // Rafraîchir la liste des transactions
    } catch (error) {
      console.error('Erreur lors de la soumission de la transaction :', error);
      // Afficher le message d'erreur si disponible
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`Erreur : ${error.response.data.message}`);
      } else {
        toast.error('Erreur lors de la soumission de la transaction.');
      }
    }
  };

  useEffect(() => {
    console.log('authorizedCategories:', authorizedCategories);
  }, [authorizedCategories]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions, currentPage]);

  const openTransactionModal = (transactionId = null) => {
    setEditingTransactionId(transactionId);
    setIsTransactionModalOpen(true);
  };

  const closeTransactionModal = () => {
    setEditingTransactionId(null);
    setIsTransactionModalOpen(false);
    fetchTransactions();
  };

  const openConfirmModal = (transactionId) => {
    setTransactionToDelete(transactionId);
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setTransactionToDelete(null);
    setIsConfirmModalOpen(false);
  };

  const confirmDelete = async () => {
    if (transactionToDelete) {
      try {
        // Avant de supprimer la transaction, restaurer la quantité du produit
        const transaction = transactions.find((tx) => tx.id === transactionToDelete);
        if (transaction && transaction.product) {
          // Récupérer la quantité de la transaction à supprimer
          const quantityToRestore = parseInt(transaction.quantity, 10);

          // Récupérer la quantité actuelle du stock
          const currentStockQuantity = parseInt(transaction.product.stockQuantity, 10);

          // Calculer la nouvelle quantité de stock après la restauration
          const restoredStockQuantity = currentStockQuantity + quantityToRestore;

          // Mettre à jour le produit avec la nouvelle quantité de stock
          await updateProduct(transaction.product.id, {
            stockQuantity: restoredStockQuantity.toString(),
          });

          // Supprimer la transaction
          await deleteTransaction(transactionToDelete);
          setTransactions(transactions.filter((tx) => tx.id !== transactionToDelete));
          setTransactionToDelete(null);
          closeConfirmModal();
          toast.success('Transaction supprimée avec succès et stock réapprovisionné.');
        }
      } catch (error) {
        console.error('Erreur lors de la suppression de la transaction:', error);
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(`Erreur : ${error.response.data.message}`);
        } else {
          toast.error('Erreur lors de la suppression de la transaction.');
        }
      }
    }
  };

  const handleCopy = (transaction) => {
    // Accéder directement au nom et ID du produit depuis la transaction
    const productId = transaction.product?.id || 'Inconnu';
    const productName = transaction.product?.name || 'Inconnu';

    // Accéder directement aux informations de l'utilisateur si disponible
    const userId = transaction.user?.id || 'Inconnu';
    const userEmail = transaction.user?.email || 'Inconnu';

    const transactionString = `
ID: ${transaction.id}
Utilisateur ID: ${userId}
Utilisateur Email: ${userEmail}
Produit ID: ${productId}
Nom du Produit: ${productName}
Quantité: ${transaction.quantity}
Montant Total: ${transaction.totalAmount} €
Type de Paiement: ${transaction.paymentType}
Statut: ${transaction.status}
Créé le: ${new Date(transaction.createdAt).toLocaleString()}
Mis à jour le: ${
      transaction.updatedAt
        ? new Date(transaction.updatedAt).toLocaleString()
        : 'Non mis à jour'
    }
Montant de Commission: ${transaction.commissionAmount} €
Titre: ${transaction.title}
    `;
    navigator.clipboard
      .writeText(transactionString)
      .then(() => {
        toast.success('Transaction copiée dans le presse-papiers.');
      })
      .catch(() => {
        toast.error('Erreur lors de la copie de la transaction.');
      });
  };

  // Calcul des commissions par statut
  const calculateCommissions = () => {
    const commissions = {
      pending: 0,
      completed: 0,
      cancelled: 0,
    };

    transactions.forEach((tx) => {
      if (tx.status === 'Pending') {
        commissions.pending += parseFloat(tx.commissionAmount) || 0;
      } else if (tx.status === 'Completed') {
        commissions.completed += parseFloat(tx.commissionAmount) || 0;
      } else if (tx.status === 'Cancelled') {
        commissions.cancelled += parseFloat(tx.commissionAmount) || 0;
      }
    });

    return commissions;
  };

  const commissions = calculateCommissions();

  // Rapports par jour et par mois
  const generateDailyReports = () => {
    const reportsArray = [];

    // Grouper par jour
    const reports = {};
    transactions.forEach((tx) => {
      const date = new Date(tx.createdAt);
      const day = date.toLocaleDateString('fr-FR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      if (!reports[day]) {
        reports[day] = {
          date: day,
          dateObj: date, // Ajout pour le tri
          transactions: 0,
          totalAmount: 0,
          commissions: 0,
        };
      }

      reports[day].transactions += 1;
      reports[day].totalAmount += parseFloat(tx.totalAmount) || 0;
      reports[day].commissions += parseFloat(tx.commissionAmount) || 0;
    });

    // Convertir en tableau trié du plus récent au plus ancien
    Object.values(reports)
      .sort((a, b) => b.dateObj - a.dateObj)
      .forEach((report) => reportsArray.push(report));

    return reportsArray;
  };


  const generateMonthlyReports = () => {
    const reportsArray = [];

    // Grouper par mois
    const reports = {};
    transactions.forEach((tx) => {
      const date = new Date(tx.createdAt);
      const month = date.toLocaleString('fr-FR', {
        month: 'long',
        year: 'numeric',
      });

      if (!reports[month]) {
        reports[month] = {
          month: month,
          dateObj: new Date(date.getFullYear(), date.getMonth(), 1), // Ajout pour le tri
          transactions: 0,
          totalAmount: 0,
          commissions: 0,
        };
      }

      reports[month].transactions += 1;
      reports[month].totalAmount += parseFloat(tx.totalAmount) || 0;
      reports[month].commissions += parseFloat(tx.commissionAmount) || 0;
    });

    // Convertir en tableau trié du plus récent au plus ancien
    Object.values(reports)
      .sort((a, b) => b.dateObj - a.dateObj)
      .forEach((report) => reportsArray.push(report));

    return reportsArray;
  };


  const dailyReportsArray = generateDailyReports();
  const monthlyReportsArray = generateMonthlyReports();

  const filteredTransactions = transactions
  .filter((transaction) => {
    // Filtre par recherche
    const matchesSearch = transaction.totalAmount
      .toString()
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    
    // Filtre par date
    const transactionDate = new Date(transaction.createdAt);
    let matchesDate = true;

    if (dateFilterMode === 'single' && startDate) {
      matchesDate = transactionDate.toDateString() === startDate.toDateString();
    } else if (dateFilterMode === 'range' && startDate && endDate) {
      matchesDate = transactionDate >= startDate && transactionDate <= endDate;
    } else if (dateFilterMode === 'month' && startDate) {
      matchesDate =
        transactionDate.getMonth() === startDate.getMonth() &&
        transactionDate.getFullYear() === startDate.getFullYear();
    }

    // Filtre par statut
    const matchesStatus = statusFilter === 'All' || transaction.status === statusFilter;

    // Vérification des catégories autorisées
    let isAuthorized = true;
    if (!isAdmin) {
      const categoryId = transaction.product?.category?.['@id'];
      const extractedCategoryId = categoryId?.split('/').pop();

      if (extractedCategoryId) {
        isAuthorized = authorizedCategories.some(
          (cat) => String(cat.id) === extractedCategoryId
        );
      } else {
        console.log('Transaction sans catégorie :', transaction);
        isAuthorized = false;
      }
    }

    return matchesSearch && matchesDate && matchesStatus && isAuthorized;
  })
  // Trier du plus récent au plus ancien
  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const getCommissionAmount = (amount) => (isNaN(amount) ? 0 : amount);

  const translateStatus = (status) => {
    switch (status) {
      case 'Pending':
        return 'En attente';
      case 'Completed':
        return 'Terminé';
      case 'Cancelled':
        return 'Annulé';
      default:
        return status; // Si le statut ne correspond à aucun cas, retourne le statut original
    }
  };
// Composant pour les contrôles de pagination
const PaginationControls = () => (
  <PaginationContainer>
    <PaginationButton
      disabled={currentPage === 1}
      onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
    >
      Précédent
    </PaginationButton>
    {Array.from({ length: totalPages }, (_, index) => (
      <PaginationButton
        key={index + 1}
        active={currentPage === index + 1}
        onClick={() => setCurrentPage(index + 1)}
      >
        {index + 1}
      </PaginationButton>
    ))}
    <PaginationButton
      disabled={currentPage === totalPages}
      onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
    >
      Suivant
    </PaginationButton>
  </PaginationContainer>
);

  return (
    <Container style={{paddingBottom:80}}>
      <Header
        title="Transactions"
        onSearch={setSearchQuery}
        onToggleView={setViewMode}
        viewMode={viewMode}
      />

      {/* Afficher les rapports et les commissions uniquement pour les administrateurs */}
      {isAdmin && (
        <ReportsContainerStyled style={{ marginBottom: 50 }}>
          <ReportTitle style={{ fontSize: 25, textAlign: 'center' }}>
            Transaction
          </ReportTitle>
          <TransactionIcon style={{ textAlign: 'center', margin: 'auto' }} />

          <ReportSectionStyled>
            <ReportTitle>Commissions</ReportTitle>
            <ReportItem>
              <strong>En attente :</strong> {commissions.pending.toFixed(2)} €
            </ReportItem>
            <ReportItem>
              <strong>Terminé :</strong> {commissions.completed.toFixed(2)} €
            </ReportItem>
            <ReportItem>
              <strong>Annulé :</strong> {commissions.cancelled.toFixed(2)} €
            </ReportItem>
          </ReportSectionStyled>

          {/* Rapport Quotidien */}
          <ReportSectionStyled>
            <ReportTitle>Rapports Quotidiens</ReportTitle>
            <hr />
            {dailyReportsArray.length === 0 ? (
              <p>Aucun rapport disponible.</p>
            ) : (
              <Slider {...sliderSettings}>
                {dailyReportsArray.map((report, index) => (
                  <ReportSectionStyled key={index}>
                    <ReportHeader>
                      <ReportTitle>Rapport du {report.date}</ReportTitle>
                    </ReportHeader>
                    <ReportItem>
                      <strong>Transactions :</strong> {report.transactions}
                    </ReportItem>
                    <ReportItem>
                      <strong>Montant Total :</strong>{' '}
                      {report.totalAmount.toFixed(2)} €
                    </ReportItem>
                    <ReportItem>
                      <strong>Commissions :</strong>{' '}
                      {report.commissions.toFixed(2)} €
                    </ReportItem>
                  </ReportSectionStyled>
                ))}
              </Slider>
            )}
          </ReportSectionStyled>

          {/* Rapport Mensuel */}
          <ReportSectionStyled>
            <ReportTitle>Rapports Mensuels</ReportTitle>
            <hr />
            {monthlyReportsArray.length === 0 ? (
              <p>Aucun rapport disponible.</p>
            ) : (
              <Slider {...sliderSettings}>
                {monthlyReportsArray.map((report, index) => (
                  <ReportSectionStyled key={index}>
                    <ReportHeader>
                      <ReportTitle>Rapport de {report.month}</ReportTitle>
                    </ReportHeader>
                    <ReportItem>
                      <strong>Transactions :</strong> {report.transactions}
                    </ReportItem>
                    <ReportItem>
                      <strong>Montant Total :</strong>{' '}
                      {report.totalAmount.toFixed(2)} €
                    </ReportItem>
                    <ReportItem>
                      <strong>Commissions :</strong>{' '}
                      {report.commissions.toFixed(2)} €
                    </ReportItem>
                  </ReportSectionStyled>
                ))}
              </Slider>
            )}
          </ReportSectionStyled>
        </ReportsContainerStyled>
      )}

      {loading ? (
        <EmptyState>
          <IonSpinner name="crescent" />
          <p>Chargement des transactions...</p>
        </EmptyState>
      ) : filteredTransactions.length === 0 ? (
        // Afficher l'état vide si l'utilisateur n'a aucune transaction
        <EmptyState>
          <EmptyIcon />
          <p>Aucune transaction disponible.</p>
          <FilterButtonContainer>
  <FilterButton active={statusFilter === 'All'} onClick={() => handleStatusChange('All')}>Toutes</FilterButton>
  <FilterButton active={statusFilter === 'Pending'} onClick={() => handleStatusChange('Pending')}>En attente</FilterButton>
  <FilterButton active={statusFilter === 'Completed'} onClick={() => handleStatusChange('Completed')}>Terminé</FilterButton>
  <FilterButton active={statusFilter === 'Cancelled'} onClick={() => handleStatusChange('Cancelled')}>Annulé</FilterButton>
</FilterButtonContainer>

        </EmptyState>
      ) : (
        <>
     <FilterButtonContainer>
  <FilterButton active={statusFilter === 'All'} onClick={() => handleStatusChange('All')}>Toutes</FilterButton>
  <FilterButton active={statusFilter === 'Pending'} onClick={() => handleStatusChange('Pending')}>En attente</FilterButton>
  <FilterButton active={statusFilter === 'Completed'} onClick={() => handleStatusChange('Completed')}>Terminé</FilterButton>
  <FilterButton active={statusFilter === 'Cancelled'} onClick={() => handleStatusChange('Cancelled')}>Annulé</FilterButton>
</FilterButtonContainer>

{/* Afficher les contrôles de pagination uniquement s'il y a plus de 20 transactions */}
{totalItems <= 20 && <PaginationControls />}
          {/* Section des Transactions */}
          {viewMode === 'grid' ? (
            <GridContainer>
              {filteredTransactions.map((transaction) => (
                <TransactionCard
                  key={transaction.id}
                  style={{ padding: '55px 25px', paddingTop: 50 }}
                >
                  <TransactionInfo>
                    <TransactionTitle>{transaction.title}</TransactionTitle>
                    <TransactionAmount>
                      Montant Total : {transaction.totalAmount} €
                    </TransactionAmount>
                    <TransactionAmount>
                      Produit : {transaction.product?.name || 'Inconnu'}
                    </TransactionAmount>
                    <TransactionAmount>
                      Stock restant :{' '}
                      {transaction.product?.stockQuantity || 'Inconnu'}
                    </TransactionAmount>
                    <TransactionAmount>
                      Réalisée par : {transaction.user?.email || 'Inconnu'}
                    </TransactionAmount>
                    <TransactionAmount>
                      Commission :{' '}
                      {getCommissionAmount(transaction.commissionAmount)} €
                    </TransactionAmount>
                    <TransactionAmount>
                      Statut : {translateStatus(transaction.status)}
                    </TransactionAmount>
                  </TransactionInfo>
                  <TransactionDate>
                    {new Date(transaction.createdAt).toLocaleDateString()}
                  </TransactionDate>
                  {/* Seuls les administrateurs peuvent modifier et supprimer */}
                  <ActionButtonGroup>
                    {isAdmin && (
                      <>
                        <ActionButton
                          color="#34C759"
                          hoverColor="#2DA647"
                          onClick={() => openTransactionModal(transaction.id)}
                          aria-label="Modifier la transaction"
                        >
                          <FaPen />
                        </ActionButton>
                        <ActionButton
                          color="#FF3B30"
                          hoverColor="#D9291C"
                          onClick={() => openConfirmModal(transaction.id)}
                          aria-label="Supprimer la transaction"
                        >
                          <FaTrash />
                        </ActionButton>
                      </>
                    )}
                    <ActionButton
                      color="#007AFF"
                      hoverColor="#005bb5"
                      onClick={() => handleCopy(transaction)}
                      aria-label="Copier la transaction"
                    >
                      <FaCopy />
                    </ActionButton>
                  </ActionButtonGroup>
                </TransactionCard>
              ))}
            </GridContainer>
          ) : (
            <ListContainer>
              {filteredTransactions.map((transaction) => (
                <TransactionCard key={transaction.id}>
                  <TransactionInfo>
                    <TransactionTitle>{transaction.title}</TransactionTitle>
                    <TransactionAmount>
                      Montant Total : {transaction.totalAmount} €
                    </TransactionAmount>
                    <TransactionAmount>
                      Produit : {transaction.product?.name || 'Inconnu'}
                    </TransactionAmount>
                    <TransactionAmount>
                      Stock restant :{' '}
                      {transaction.product?.stockQuantity || 'Inconnu'}
                    </TransactionAmount>
                    <TransactionAmount>
                      Transaction réalisée par :{' '}
                      {transaction.user?.email || 'Inconnu'}
                    </TransactionAmount>
                    <TransactionAmount>
                      Commission :{' '}
                      {getCommissionAmount(transaction.commissionAmount)} €
                    </TransactionAmount>
                    <TransactionAmount>
                      Statut : {translateStatus(transaction.status)}
                    </TransactionAmount>
                  </TransactionInfo>
                  <TransactionDate>
                    {new Date(transaction.createdAt).toLocaleDateString()}
                  </TransactionDate>
                  {/* Seuls les administrateurs peuvent modifier et supprimer */}
                  <ActionButtonGroup>
                    {isAdmin && (
                      <>
                        <ActionButton
                          color="#34C759"
                          hoverColor="#2DA647"
                          onClick={() => openTransactionModal(transaction.id)}
                          aria-label="Modifier la transaction"
                        >
                          <FaPen />
                        </ActionButton>
                        <ActionButton
                          color="#FF3B30"
                          hoverColor="#D9291C"
                          onClick={() => openConfirmModal(transaction.id)}
                          aria-label="Supprimer la transaction"
                        >
                          <FaTrash />
                        </ActionButton>
                      </>
                    )}
                    <ActionButton
                      color="#007AFF"
                      hoverColor="#005bb5"
                      onClick={() => handleCopy(transaction)}
                      aria-label="Copier la transaction"
                    >
                      <FaCopy />
                    </ActionButton>
                  </ActionButtonGroup>
                </TransactionCard>
              ))}
            </ListContainer>
          )}
        </>
      )}

      {/* Seuls les administrateurs peuvent ajouter des transactions */}
      {/* {isAdmin && ( */}
      <AddButton
        onClick={() => openTransactionModal(null)}
        aria-label="Ajouter une transaction"
      >
        <FaPlus style={{ fontSize: 20 }} />
      </AddButton>
      {/* // )} */}

      {/* Modale pour ajouter/modifier une transaction */}
      <TransactionModal
        isOpen={isTransactionModalOpen}
        onRequestClose={closeTransactionModal}
        onSubmit={handleTransactionSubmit}
        transactionData={
          editingTransactionId
            ? transactions.find((tx) => tx.id === editingTransactionId)
            : null
        }
        userId={user?.id} // Utiliser l'ID de l'utilisateur défini
      />

      {/* Modale de confirmation pour supprimer une transaction */}
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onRequestClose={closeConfirmModal}
        onConfirm={confirmDelete}
        title="Confirmer la Suppression"
        message="Êtes-vous sûr de vouloir supprimer cette transaction ?"
      />
    </Container>
  );
};

export default TransactionList;
