// src/components/HamburgerButton.jsx
import React from 'react';
import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';
import PropTypes from 'prop-types';

const Button = styled.button`
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1100;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: #333;
  transition: color 0.2s ease;

  &:hover {
    color: #007AFF;
  }

  @media (min-width: 768px) {
    display: none; /* Masquer le bouton sur les écrans plus larges */
  }
`;

const HamburgerIcon = styled(FaBars)`
  font-size: 24px;
`;

const HamburgerButton = ({ toggleMenu }) => (
  <Button onClick={toggleMenu} aria-label="Ouvrir le menu">
    <HamburgerIcon />
  </Button>
);

HamburgerButton.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
};

export default HamburgerButton;
