// src/hooks/useLongPress.js
import { useState, useEffect, useCallback, useRef } from 'react';

/**
 * Hook personnalisé pour détecter une pression prolongée sur un élément.
 *
 * @param {Function} onLongPress - Fonction à exécuter lors d'une pression prolongée.
 * @param {Function} onClick - Fonction à exécuter lors d'un clic normal.
 * @param {Object} options - Options de configuration.
 * @param {number} options.delay - Durée en millisecondes pour considérer une pression comme prolongée.
 * @returns {Object} - Retourne une référence à attacher à l'élément et un état indiquant si une pression est en cours.
 */
const useLongPress = (onLongPress, onClick, { delay = 200 } = {}) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const [isPressing, setIsPressing] = useState(false); // État pour le feedback visuel
  const timeoutRef = useRef();
  const targetRef = useRef();

  const start = useCallback(
    (event) => {
      console.log('Press started');
      setIsPressing(true);
      event.preventDefault();
      timeoutRef.current = setTimeout(() => {
        console.log('Long press triggered');
        onLongPress(event);
        setLongPressTriggered(true);
        setIsPressing(false);
      }, delay);
    },
    [onLongPress, delay]
  );

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      console.log('Press cleared');
      clearTimeout(timeoutRef.current);
      if (shouldTriggerClick && !longPressTriggered) {
        console.log('Triggering click');
        onClick(event);
      }
      setLongPressTriggered(false);
      setIsPressing(false);
    },
    [onClick, longPressTriggered]
  );

  useEffect(() => {
    const element = targetRef.current;
    if (element) {
      element.addEventListener('mousedown', start);
      element.addEventListener('touchstart', start);
      element.addEventListener('mouseup', clear);
      element.addEventListener('touchend', clear);
    //   element.addEventListener('mouseleave', clear);
    }

    return () => {
      if (element) {
        element.removeEventListener('mousedown', start);
        element.removeEventListener('touchstart', start);
        element.removeEventListener('mouseup', clear);
        element.removeEventListener('touchend', clear);
        // element.removeEventListener('mouseleave', clear);
      }
    };
  }, [start, clear]);

  return { ref: targetRef, isPressing };
};

export default useLongPress;
