import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';

// Animation pour le spinner
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Container = styled.div`
  padding: 20px;
  background-color: #F2F2F7;
  height: 100vh;
  overflow-y: scroll;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
`;

const Section = styled.div`
  background-color: white;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #F7F7F7;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SectionTitle = styled.h3`
  color: #333;
  font-size: 16px;
  font-weight: 500;
`;

const Chevron = styled.span`
  display: inline-block;
  transform: rotate(0deg);
  color: #C7C7CC;
  font-size: 20px;
  transition: transform 0.3s ease;

  ${Section}:hover & {
    transform: translateX(5px);
  }
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 51px;
  height: 31px;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ddd;
  border-radius: 34px;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    border-radius: 50%;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
  }
`;

const SwitchInputChecked = styled(SwitchInput)`
  &:checked + ${Slider} {
    background-color: #4cd964;
  }

  &:checked + ${Slider}:before {
    transform: translateX(20px);
  }
`;

// Bouton de mise à jour
const RefreshButton = styled.button`
  background-color: #007AFF;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #005BBB;
  }

  &:disabled {
    background-color: #B0B0B0;
  }
`;

// Spinner pour l'effet de chargement
const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spin} 1s linear infinite;
  margin-left: 10px;
`;

const Settings = () => {
  const [isNotificationsEnabled, setNotificationsEnabled] = useState(true);
  const [isDarkModeEnabled, setDarkModeEnabled] = useState(false);
  const [isRefreshing, setRefreshing] = useState(false);
  const navigate = useNavigate();

  const goToPrivacy = () => navigate('/privacy');
  const goToAbout = () => navigate('/about');

  // Fonction pour rafraîchir la page
  const handleRefresh = () => {
    setRefreshing(true);
    setTimeout(() => {
      window.location.reload(); // Recharge la page après une courte attente
    }, 1500); // Attente de 1.5s pour montrer l'animation de chargement
  };

  return (
    <Container>
      <Section>
        <SectionHeader>
          <SectionTitle>Notifications</SectionTitle>
          <ToggleSwitch>
            <SwitchInputChecked
              type="checkbox"
              checked={isNotificationsEnabled}
              onChange={() => setNotificationsEnabled(!isNotificationsEnabled)}
            />
            <Slider />
          </ToggleSwitch>
        </SectionHeader>
      </Section>

      <Section>
        <SectionHeader>
          <SectionTitle>Mode sombre</SectionTitle>
          <ToggleSwitch>
            <SwitchInputChecked
              type="checkbox"
              checked={isDarkModeEnabled}
              onChange={() => setDarkModeEnabled(!isDarkModeEnabled)}
            />
            <Slider />
          </ToggleSwitch>
        </SectionHeader>
      </Section>

      <Section onClick={goToPrivacy}>
        <SectionHeader>
          <SectionTitle>Confidentialité</SectionTitle>
          <Chevron>›</Chevron>
        </SectionHeader>
      </Section>

      <Section onClick={goToAbout}>
        <SectionHeader>
          <SectionTitle>À propos</SectionTitle>
          <Chevron>›</Chevron>
        </SectionHeader>
      </Section>

      {/* Bouton de mise à jour */}
      <RefreshButton onClick={handleRefresh} disabled={isRefreshing}>
        {isRefreshing ? (
          <>
            Mise à jour en cours
            <Spinner />
          </>
        ) : (
          "Mettre à jour"
        )}
      </RefreshButton>
    </Container>
  );
};

export default Settings;
