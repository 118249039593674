import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styled, { keyframes } from 'styled-components';
import { getProduct, createProduct, updateProduct } from '../api/ProductService';
import { toast } from 'react-toastify';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { getCategories } from '../api/CategoryService';
import Select from 'react-select';

// Animation d'apparition et de disparition
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
`;

// Overlay styled comme iOS avec des effets subtils de transparence et d'ombrage
const StyledOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  transition: opacity 300ms ease-in-out;
  animation: ${fadeIn} 0.3s ease-out forwards;

  &.ReactModal__Overlay--before-close {
    animation: ${fadeOut} 0.3s ease-out forwards;
  }
`;

// Contenu du modal avec un design plus "iOS-like"
const StyledContent = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  height: auto;
  max-height: 80vh;
  height: ${({ showCommission }) => (showCommission ? '75vh' : '65vh')};
  margin: auto;
  text-align: center;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  position: relative;
  animation: ${fadeIn} 0.3s ease-out forwards;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;

  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;

// Bouton de fermeture en haut à droite avec le style d'iOS
const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: #8e8e93;
  cursor: pointer;
  font-size: 24px;

  &:hover {
    color: #007AFF;
  }
`;

// Formulaire stylisé dans l'esprit minimaliste d'iOS
const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const FormTitle = styled.h2`
  color: #007AFF;
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
`;

// Champs de formulaire avec des bords arrondis et une animation subtile sur le focus
const FormGroup = styled.div`
  margin-bottom: 15px;
  text-align: left;
`;

const ConditionalFormGroup = styled(FormGroup)`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const Label = styled.label`
  font-size: 16px;
  color: #1c1c1e;
  margin-bottom: 8px;
  display: block;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  background-color: #f2f2f7;
  border: 1px solid #d1d1d6;
  border-radius: 12px;
  color: #1c1c1e;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;

  &:focus {
    outline: none;
    border-color: #007AFF;
    box-shadow: 0 0 8px rgba(0, 122, 255, 0.2);
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  background-color: #f2f2f7;
  border: 1px solid #d1d1d6;
  border-radius: 12px;
  color: #1c1c1e;
  resize: vertical;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;

  &:focus {
    outline: none;
    border-color: #007AFF;
    box-shadow: 0 0 8px rgba(0, 122, 255, 0.2);
  }
`;

// Boutons stylisés avec un effet de légère élévation au survol
const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 25px;
`;

const Button = styled.button`
  flex: 1;
  padding: 14px 20px;
  border-radius: 10px;
  font-size: 16px;
  background-color: ${({ color }) => color || '#007AFF'};
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    background-color: ${({ color }) => color ? '#005BB5' : '#005BB5'};
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const CancelButton = styled(Button)`
  background-color: #f2f2f7;
  color: #1c1c1e;

  &:hover {
    background-color: #e5e5ea;
  }
`;

// Groupe pour le switch permettant d'activer/désactiver la commission
const SwitchGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

// Composant ProductModal
const ProductModal = ({ isOpen, onRequestClose, productId }) => {
  const isEditing = Boolean(productId);

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    stockQuantity: '',
    commissionAmount: '',
    category: null, // Nouvelle propriété pour la catégorie
  });

  const [categories, setCategories] = useState([]); // Stocker les catégories disponibles
  const [loading, setLoading] = useState(false);
  const [showCommission, setShowCommission] = useState(true);

  useEffect(() => {
    if (isEditing && isOpen) {
      fetchProduct(productId);
    } else if (!isEditing && isOpen) {
      resetForm();
    }
    fetchCategories(); // Charger les catégories lorsque le modal est ouvert
  }, [isEditing, isOpen]);

  const resetForm = () => {
    setFormData({
      name: '',
      description: '',
      price: '',
      stockQuantity: '',
      commissionAmount: '',
      category: null, // Réinitialiser la catégorie
    });
    setShowCommission(true);
  };

  // Fonction pour charger le produit à modifier
  const fetchProduct = async (id) => {
    setLoading(true);
    try {
      const product = await getProduct(id);
      setFormData({
        name: product.name || '',
        description: product.description || '',
        price: product.price ? String(product.price) : '',
        stockQuantity: product.stockQuantity ? String(product.stockQuantity) : '',
        commissionAmount: product.commissionAmount ? String(product.commissionAmount) : '',
        category: product.category ? { value: product.category.id, label: product.category.name } : null,
      });
      setShowCommission(Boolean(product.commissionAmount));
    } catch (error) {
      toast.error('Erreur lors de la récupération du produit.');
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour charger les catégories
  const fetchCategories = async () => {
    try {
      const data = await getCategories();
      const categoryOptions = data.member.map((cat) => ({ value: cat.id, label: cat.name }));
      setCategories(categoryOptions);
    } catch (error) {
      toast.error('Erreur lors du chargement des catégories.');
    }
  };

  // Gestion du changement de champ
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Gestion du changement de catégorie
  const handleCategoryChange = (selectedCategory) => {
    setFormData({ ...formData, category: selectedCategory });
  };

  // Soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.price || !formData.stockQuantity || !formData.category) {
      toast.error('Tous les champs obligatoires doivent être remplis.');
      return;
    }

    const productPayload = {
      name: formData.name.trim(),
      description: formData.description.trim(),
      price: String(parseFloat(formData.price)),
      stockQuantity: String(parseInt(formData.stockQuantity, 10)),
      commissionAmount: showCommission ? String(parseFloat(formData.commissionAmount)) : null,
      category: `/api/categories/${formData.category.value}`, // Envoi de la catégorie sélectionnée
    };

    try {
      setLoading(true);
      if (isEditing) {
        await updateProduct(productId, productPayload);
        toast.success('Produit mis à jour avec succès.');
      } else {
        await createProduct(productPayload);
        toast.success('Produit créé avec succès.');
      }
      onRequestClose();
    } catch (error) {
      toast.error('Erreur lors de la soumission du produit.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeTimeoutMS={300}
      ariaHideApp={false}
      contentLabel={isEditing ? 'Modifier Produit' : 'Ajouter Produit'}
      contentElement={(props, children) => <StyledContent {...props} showCommission={showCommission}>{children}</StyledContent>}
      overlayElement={(props, contentElement) => <StyledOverlay {...props}>{contentElement}</StyledOverlay>}
    >
      <CloseButton onClick={onRequestClose} aria-label="Fermer">
        <FaTimes />
      </CloseButton>

      <Form onSubmit={handleSubmit}>
        <FormTitle>{isEditing ? 'Modifier Produit' : 'Ajouter Nouveau Produit'}</FormTitle>

        <FormGroup>
          <Label htmlFor="name">Nom du Produit</Label>
          <Input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="description">Description</Label>
          <TextArea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="price">Prix (€)</Label>
          <Input
            type="number"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleChange}
            step="0.01"
            min="0"
            required
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="stockQuantity">Quantité en Stock</Label>
          <Input
            type="number"
            id="stockQuantity"
            name="stockQuantity"
            value={formData.stockQuantity}
            onChange={handleChange}
            min="0"
            required
          />
        </FormGroup>

        {/* Sélecteur de catégorie */}
        <FormGroup>
          <Label htmlFor="category">Catégorie</Label>
          <Select
            id="category"
            name="category"
            value={formData.category}
            onChange={handleCategoryChange}
            options={categories}
            isClearable
            required
          />
        </FormGroup>

        <SwitchGroup>
          <Label>Ajouter une Commission</Label>
          <Switch
            onChange={() => setShowCommission(!showCommission)}
            checked={showCommission}
            onColor="#007AFF"
          />
        </SwitchGroup>

        {showCommission && (
          <FormGroup>
            <Label htmlFor="commissionAmount">Montant de la Commission (€)</Label>
            <Input
              type="number"
              id="commissionAmount"
              name="commissionAmount"
              value={formData.commissionAmount}
              onChange={handleChange}
              step="0.01"
              min="0"
            />
          </FormGroup>
        )}

        <ButtonGroup>
          <CancelButton type="button" onClick={onRequestClose}>
            Annuler
          </CancelButton>
          <Button type="submit" disabled={loading}>
            {isEditing ? 'Mettre à Jour' : 'Créer'}
          </Button>
        </ButtonGroup>
      </Form>
    </Modal>
  );
};

ProductModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  productId: PropTypes.number,
};

export default ProductModal;
