import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getProducts, deleteProduct } from '../api/ProductService';
import { FaPen, FaTrash, FaPlus, FaBoxOpen } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Header from '../components/Header';
import ProductModal from '../components/ProductModal';
import ConfirmModal from '../components/ConfirmModal';
import { IonCardTitle, IonSpinner } from '@ionic/react';
import { useAuth } from '../contexts/AuthContext'; // Assure-toi d'importer correctement ton contexte d'authentification
import axios from 'axios';
import api from '../axiosConfig';

// Conteneur global
const Container = styled.div`
  padding: 20px;
  background-color: #f7f7f7;
  min-height: 100vh;
  font-family: ${({ theme }) => theme.fonts.regular};
`;

// Grille pour l'affichage en cartes
const GridContainer = styled.div`
  display: grid;
  padding-bottom: 100px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Deux cartes par ligne */
  gap: 20px;
`;

// Liste pour l'affichage en liste
const ListContainer = styled.div`
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

// List Item de produit (affichage en liste)
const ProductListItem = styled.div`
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

// Cartes de produit
const ProductCard = styled.div`
  background-color: #fff;
  padding: 15px 10px; /* Réduire les marges pour plus de compacité */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
`;
const GridContainerStockInfo = styled.div`
  display: flex;
  justify-content: space-between; /* Espace entre les éléments */
  align-items: center; /* Centrer verticalement les éléments */
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  
  /* Ajout de cette ligne pour s'assurer que chaque enfant occupe 50% */
  & > div {
    flex-basis: 50%; /* Chaque conteneur occupe 50% de l'espace */
  }
`;

const StockSummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;


const StockValue = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #007aff;
`;


// Informations sur le produit
const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const ProductName = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
`;

const ProductDescription = styled.p`
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
`;

const ProductPrice = styled.p`
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
`;

const ProductStock = styled.p`
  font-size: 12px;
  color: #666;
`;

// Groupe de boutons d'action avec icônes réduits
const ActionButtonGroup = styled.div`
  display: flex;
  gap: 5px;
`;

const ActionButton = styled.button`
  background-color: ${({ color }) => color};
  color: #fff;
  border: none;
  padding: 6px;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    background-color: ${({ hoverColor }) => hoverColor};
  }

  svg {
    font-size: 14px; /* Réduire la taille des icônes */
  }
`;
// Empty state
const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  color: #888;
  font-size: 18px;
  text-align: center;
`;

const EmptyIcon = styled(FaBoxOpen)`
  font-size: 50px;
  margin-bottom: 15px;
  color: #ccc;
`;
// Add transaction button
const AddButton = styled.button`
  position: fixed;
  bottom: 60px;
  right: 20px;
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #005bb5;
    transform: scale(1.05);
  }
`;
// Titre pour les rapports
const ReportTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
 
`;
const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [editingProductId, setEditingProductId] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [viewMode, setViewMode] = useState('grid'); // Gestion du mode d'affichage
  const [searchQuery, setSearchQuery] = useState(''); // Recherche
  const [sortKey, setSortKey] = useState('name'); // Tri par défaut
  const [totalStockValue, setTotalStockValue] = useState(0);
  const [totalProductsInStock, setTotalProductsInStock] = useState(0);

  const { auth, setAuth } = useAuth(); // Utilisation de setAuth ici
  const { user } = auth || {};
  const authorizedCategories = user?.authorizedCategories || [];
  console.log('Catégories autorisées :', authorizedCategories);

  


  const fetchProducts = useCallback(async () => {
    try {
      const data = await getProducts();
      const productsList = data['hydra:member'] || data.member || [];

      setProducts(productsList);

      // Calculer le montant total et le nombre total de produits en stock
      const totalValue = productsList.reduce(
        (acc, product) => acc + (parseFloat(product.price) * parseInt(product.stockQuantity, 10)), 0
      );
      const totalStock = productsList.reduce(
        (acc, product) => acc + parseInt(product.stockQuantity, 10), 0
      );

      setTotalStockValue(totalValue);
      setTotalProductsInStock(totalStock);
    } catch (error) {
      toast.error('Erreur lors de la récupération des produits');
    } finally {
      setLoading(false);
    }
  }, []);

  const isAdmin = user && user.roles && user.roles.includes('ROLE_ADMIN');

  useEffect(() => {
    fetchProducts(); // Récupérez les produits au chargement du composant
  }, []);
  const updateAuthorizedCategories = async () => {
    try {
      const response = await api.get('/check');
      const user = response.data;
      setAuth(prevAuth => ({
        ...prevAuth,
        user: {
          ...prevAuth.user,
          authorizedCategories: user.authorizedCategories.map((category) => ({
            id: category.id,
            name: category.name,
          })),
        },
      }));
    } catch (error) {
      console.error('Erreur lors de la mise à jour des catégories autorisées:', error);
    }
  };

  useEffect(() => {
    fetchProducts(); // Récupérez les produits au chargement du composant
    updateAuthorizedCategories(); // Mettez à jour les catégories autorisées
  }, [fetchProducts]);

  // Polling for products and authorized categories
  useEffect(() => {
    const interval = setInterval(() => {
      fetchProducts();
      updateAuthorizedCategories();
    }, 5000); // 10 secondes

    return () => clearInterval(interval); // Nettoyage de l'intervalle
  }, [fetchProducts]);
  const filteredProducts = products
  .filter((product) => {
    if (user?.roles.includes('ROLE_ADMIN')) {
      return true; // L'administrateur peut tout voir
    }
    const productCategoryId = product.category?.['@id'];
    const extractedCategoryId = productCategoryId?.split('/').pop();

    return authorizedCategories.some(cat => String(cat.id) === extractedCategoryId);
  })
  .filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  )
  .sort((a, b) => {
    if (sortKey === 'name') {
      return a.name.localeCompare(b.name);
    } else if (sortKey === 'price') {
      return a.price - b.price;
    }
    return 0;
  });


  const openProductModal = (productId = null) => {
    setEditingProductId(productId);
    setIsProductModalOpen(true);
  };

  const closeProductModal = () => {
    setEditingProductId(null);
    setIsProductModalOpen(false);
    fetchProducts(); // Refresh product list after modal close
    toast.success(editingProductId ? 'Produit modifié avec succès' : 'Produit créé avec succès');
  };

  const openConfirmModal = (productId) => {
    setProductToDelete(productId);
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setProductToDelete(null);
    setIsConfirmModalOpen(false);
  };

  const confirmDelete = async () => {
    if (productToDelete) {
      try {
        await deleteProduct(productToDelete);
        setProducts(products.filter((product) => product.id !== productToDelete));
        setProductToDelete(null);
        closeConfirmModal();
        toast.success('Produit supprimé avec succès');
      } catch (error) {
        toast.error('Erreur lors de la suppression du produit');
      }
    }
  };

  const handleSortChange = (sortKey) => {
    setSortKey(sortKey);
  };

  



  if (loading) {
    return (
      <Container>
        <Header title="Produits" />
        <IonSpinner />
        <p>Chargement des produits...</p>
      </Container>
  );
}

  return (
    <Container>
      <Header
        title="Produits"
        onSearch={setSearchQuery}
        onToggleView={setViewMode}
        onSortChange={handleSortChange}
        viewMode={viewMode}
      />
{isAdmin && (

<GridContainerStockInfo>
  {/* Section à gauche avec icône et titre "Stock" */}
  <div style={{ display: 'flex', alignItems: 'center', flexDirection:'column', justifyContent:'center', alignContent:'center', textAlign:'center', margin:'auto' }}>
    <FaBoxOpen style={{ fontSize: '50px',  color: '#ccc' }} />
    <ReportTitle>Stocks</ReportTitle>
  </div>

  {/* Section à droite pour le montant total du stock et le nombre total de produits */}
  <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
    <StockSummaryItem>
      <ReportTitle style={{ fontSize: '15px' }}>Montant Total du stock</ReportTitle>
      <StockValue>{totalStockValue.toFixed(2)} €</StockValue>
    </StockSummaryItem>

    <StockSummaryItem>
      <ReportTitle style={{ fontSize: '15px', marginTop: '8px' }}>Total de produit en stock</ReportTitle>
      <StockValue>{totalProductsInStock}</StockValue>
    </StockSummaryItem>
  </div>
</GridContainerStockInfo>

)}
      {loading ? (

        <EmptyState>
          <IonSpinner />
          <p>Chargement des produits...</p>
        </EmptyState>
      ) : filteredProducts.length === 0 ? (
        <EmptyState>
          <EmptyIcon />
          <p>Aucun produits disponible.</p>
        </EmptyState>
      ) : viewMode === 'grid' ? (
        <GridContainer>
          {filteredProducts.map((product) => (
            <ProductCard key={product.id} style={{ position: 'relative' }}>
              <FaBoxOpen style={{ fontSize: '60px', color: '#ccc', position: 'absolute', right: 25, bottom: 5 }} />

              <ProductInfo>
                <ProductName>{product.name}</ProductName>
                <ProductDescription>{product.description}</ProductDescription>
                <ProductPrice>Prix : {product.price} €</ProductPrice>
                <ProductStock>Stock : {product.stockQuantity}</ProductStock>
              </ProductInfo>
              {isAdmin && (
                <ActionButtonGroup style={{ position: 'sticky', right: 15, top: 15 }}>
                  <ActionButton
                    color="#34C759"
                    hoverColor="#2DA647"
                    onClick={() => openProductModal(product.id)}
                    disabled={!isAdmin} // Désactiver le bouton pour les non-admins
                  >
                    <FaPen />
                  </ActionButton>
                  <ActionButton
                    color="#FF3B30"
                    hoverColor="#D9291C"
                    onClick={() => openConfirmModal(product.id)}
                    disabled={!isAdmin} // Désactiver le bouton pour les non-admins
                  >
                    <FaTrash />
                  </ActionButton>

                </ActionButtonGroup>
              )}
            </ProductCard>
          ))}
        </GridContainer>
      ) : (
        <ListContainer>
          {filteredProducts.map((product) => (
            <ProductListItem key={product.id}>
              <ProductInfo>
                <ProductName>{product.name}</ProductName>
                <ProductDescription>{product.description}</ProductDescription>
                <ProductPrice>Prix : {product.price} €</ProductPrice>
                <ProductStock>Stock : {product.stockQuantity}</ProductStock>
              </ProductInfo>
              {isAdmin && (
              <ActionButtonGroup>
                <ActionButton
                  color="#34C759"
                  hoverColor="#2DA647"
                  onClick={() => openProductModal(product.id)}
                  disabled={!isAdmin} // Désactiver le bouton pour les non-admins
                >
                  <FaPen />
                </ActionButton>
                <ActionButton
                  color="#FF3B30"
                  hoverColor="#D9291C"
                  onClick={() => openConfirmModal(product.id)}
                  disabled={!isAdmin} // Désactiver le bouton pour les non-admins
                >
                  <FaTrash />
                </ActionButton>
              </ActionButtonGroup>
)}
            </ProductListItem>
          ))}
        </ListContainer>
      )}

{isAdmin && (
  <AddButton onClick={() => openProductModal(null)} aria-label="Ajouter un produit">
          <FaPlus style={{fontSize:20}} />
          </AddButton>
)}


      <ProductModal
        isOpen={isProductModalOpen}
        onRequestClose={closeProductModal}
        productId={editingProductId}
      />

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onRequestClose={closeConfirmModal}
        onConfirm={confirmDelete}
        title="Confirmer la Suppression"
        message="Êtes-vous sûr de vouloir supprimer ce produit ?"
      />
    </Container>
  );
};

export default ProductList;
