import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../axiosConfig';
import { toast } from 'react-toastify';
import eventEmitter from '../events';
import { jwtDecode } from 'jwt-decode';
import { IonSpinner } from '@ionic/react';
import styled from 'styled-components';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState({
    token: null,
    user: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('jwtToken');

      if (token) {
        try {
          const decoded = jwtDecode(token);
          // Vérification de l'expiration du token
          if (decoded.exp * 1000 < Date.now()) {
            throw new Error('Token expiré');
          }

          // Récupérer les détails de l'utilisateur via /check
          const response = await api.get('/check');
          const user = response.data;

          // Stocker l'utilisateur et les catégories autorisées
          setAuth({
            token,
            user: {
              ...user,
              authorizedCategories: user.authorizedCategories.map((category) => ({
                id: category.id,
                name: category.name,
              })),
            },
          });
        } catch (error) {
          console.error('Erreur lors de l\'initialisation de l\'authentification:', error);
          setAuth({ token: null, user: null });
          localStorage.removeItem('jwtToken');
          navigate('/login'); // Rediriger vers la page de connexion
        }
      } else {
        setAuth({ token: null, user: null });
      }

      const handleLogout = () => {
        toast.error('Votre session a expiré. Veuillez vous reconnecter.');
        logout();
      };

      eventEmitter.on('logout', handleLogout);
      setLoading(false);

      return () => {
        eventEmitter.off('logout', handleLogout);
      };
    };

    initializeAuth();
  }, [navigate]);

  const login = async (email, password) => {
    try {
      const response = await api.post('/auth', { email, password });
      const { token } = response.data;
      localStorage.setItem('jwtToken', token);

      const userResponse = await api.get('/check');
      setAuth({ token, user: userResponse.data });

      toast.success('Connexion réussie!');
      return { success: true };
    } catch (error) {
      console.error('Erreur lors de la connexion:', error);
      toast.error('Connexion échouée');
      return { success: false, message: 'Connexion échouée' };
    }
  };

  const logout = () => {
    localStorage.removeItem('jwtToken');
    setAuth({ token: null, user: null });
    navigate('/login');
  };

  const verifyPin = async (pin) => {
    try {
      const response = await api.post('/api/verify-pin', { pin });
      return { success: true, message: response.data.message };
    } catch (error) {
      console.error('Erreur lors de la vérification du PIN:', error);
      return { success: false, message: 'Vérification du PIN échouée' };
    }
  };

  const EmptyState = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    color: #888;
    font-size: 18px;
    text-align: center;
  `;

  return (
    <AuthContext.Provider value={{ auth, setAuth, login, logout, verifyPin }}>
      {!loading ? children : (
        <EmptyState>
          <IonSpinner name="crescent" />
          <p>Chargement des composants...</p>
        </EmptyState>
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
