// src/components/ConfirmModal.js
import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

const CustomModal = styled(Modal)`
  background: ${({ theme }) => theme.colors.cardBackground};
  padding: 20px;
  border-radius: 12px;
  max-width: 400px;
  margin: auto;
  box-shadow: 0 2px 4px ${({ theme }) => theme.colors.shadow};
  font-family: ${({ theme }) => theme.fonts.regular};
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.subText};
  cursor: pointer;
  font-size: 18px;
  
  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
`;

const Message = styled.p`
  color: ${({ theme }) => theme.colors.subText};
  margin-bottom: 20px;
  font-size: 16px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Button = styled.button`
  background-color: ${({ color, theme }) => color || theme.colors.buttonBackground};
  color: ${({ color, theme }) => (color ? '#FFFFFF' : theme.colors.buttonText)};
  border: none;
  border-radius: 8px;
  padding: 10px 18px;
  cursor: pointer;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.regular};
  transition: background-color 0.2s ease, opacity 0.2s ease;
  
  &:hover {
    opacity: 0.95;
  }
`;

const ConfirmModal = ({ isOpen, onRequestClose, onConfirm, title, message }) => (
  <CustomModal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    ariaHideApp={false}
    style={{
      overlay: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    }}
  >
    <CloseButton onClick={onRequestClose} aria-label="Fermer la modal">
      <FaTimes />
    </CloseButton>
    <Title>{title}</Title>
    <Message>{message}</Message>
    <ButtonGroup>
      <Button color="#FF3B30" onClick={onConfirm}>
        Supprimer
      </Button>
      <Button onClick={onRequestClose}>Annuler</Button>
    </ButtonGroup>
  </CustomModal>
);

export default ConfirmModal;
