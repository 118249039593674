import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaExclamationCircle } from 'react-icons/fa'; // Icône d'erreur
import api from '../axiosConfig';

// Animation de vibration pour l'erreur
const shake = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
`;

// Fade-in animation pour le message d'erreur
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #F2F2F7;
`;

const Form = styled.div`
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
`;
const Title = styled.h1`
  font-size: 24px;
  color: #1C1C1E;
  text-align: center;
  margin-bottom: 30px;
`;

const Input = styled.input`
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid #C7C7CC;
  font-size: 16px;
`;

const Button = styled.button`
  width: 100%;
  padding: 15px;
  background-color: #007AFF;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fdecea;
  color: #d93025;
  border: 1px solid #d93025;
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 20px;
  animation: ${fadeIn} 0.5s ease; /* Animation fade-in */
  font-size: 14px;
  font-weight: 500;
  
  svg {
    margin-right: 8px;
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // État de chargement
  const [shakeError, setShakeError] = useState(false); // État pour l'animation de vibration

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    setShakeError(false); // Désactiver l'animation de vibration au début

    try {
      const response = await api.post('/auth', { email, password });


      const token = response.data.token;
      // Stocker le token en localStorage
      localStorage.setItem('jwtToken', token);

      // Afficher le toast de succès
      toast.success('Connexion réussie!', {
        position: 'top-right', // Correction ici, position directe
      });

      // Rediriger vers la page d'accueil après un petit délai
      setTimeout(() => {
        window.location.href = '/';
      },200);
    } catch (err) {
      // Si une erreur survient, afficher l'animation de vibration
      setShakeError(true);
      setError('Identifiants invalides. Veuillez réessayer.');
      toast.error('Erreur de connexion. Vérifiez vos identifiants.', {
        position: 'top-right', // Correction ici, position directe
      });
    } finally {
      setLoading(false); // Réinitialiser l'état de chargement
    }
  };

  return (
    <Container>
      <Form shakeError={shakeError}>
        <Title>Connexion</Title>
        {error && (
          <ErrorMessage>
            <FaExclamationCircle /> {/* Icône d'avertissement */}
            {error}
          </ErrorMessage>
        )}
        <form onSubmit={handleLogin}>
          <Input
            type="email"
            placeholder="Adresse e-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Input
            type="password"
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button type="submit" disabled={loading}>
            {loading ? <Spinner style={{margin:'auto'}} /> : 'Se connecter'}
          </Button>
        </form>
      </Form>
      <ToastContainer />
    </Container>
  );
};

export default LoginPage;
