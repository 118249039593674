import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  background-color: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #F7F7F7;
    transform: translateY(-2px);
  }
`;

const CardTitle = styled.h4`
  color: #1C1C1E;
  font-size: 17px;
  font-weight: 500;
  margin: 0;
`;

const CardDetail = styled.p`
  color: #8E8E93;
  font-size: 14px;
  margin: 5px 0 0 0;
`;

const Chevron = styled.span`
  color: #C7C7CC;
  font-size: 18px;
  transition: transform 0.3s ease;

  ${CardContainer}:hover & {
    transform: translateX(5px);
  }
`;

const Card = ({ title, detail }) => {
  return (
    <CardContainer>
      <div>
        <CardTitle>{title}</CardTitle>
        {detail && <CardDetail>{detail}</CardDetail>}
      </div>
      <Chevron>›</Chevron>
    </CardContainer>
  );
};

export default Card;
