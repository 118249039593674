import React, { useEffect } from 'react';
import styled from 'styled-components';
import Avatar from '../components/Avatar';
import Button from '../components/Button';
import { useAuth } from '../contexts/AuthContext'; // Importez le contexte d'authentification

const Container = styled.div`
  background-color: #F2F2F7;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfileCard = styled.div`
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Name = styled.h2`
  font-size: 24px;
  color: #1C1C1E;
  margin-bottom: 10px;
`;

const Email = styled.p`
  font-size: 16px;
  color: #8E8E93;
  margin-bottom: 5px;
`;

const Role = styled.p`
  font-size: 14px;
  color: #007AFF;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Profile = () => {
  const { auth } = useAuth(); // Utilisez le contexte d'authentification
  const { user } = auth;

  useEffect(() => {
    if (!user) {
      // Redirigez vers la page de connexion ou gérez l'état de l'utilisateur non connecté
      window.location.href = '/login'; // Exemple de redirection
    }
  }, [user]);

  return (
    <Container>
      <ProfileCard>
        <Avatar />
        {user ? (
          <>
            <Name>{user.email}</Name>
            <Email>{user.email}</Email>
            <Role>{user.roles.includes('ROLE_ADMIN') ? 'Admin' : 'Utilisateur'}</Role>
            <ButtonContainer style={{display:'block'}}>
              <Button>Editer le profil</Button>
              <Button onClick={() => { /* Ajoutez une fonction pour gérer la déconnexion */ }}>Déconnexion</Button>
            </ButtonContainer>
          </>
        ) : (
          <p>Chargement des informations de l'utilisateur...</p>
        )}
      </ProfileCard>
    </Container>
  );
};

export default Profile;
