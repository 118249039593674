// src/components/CreditList.jsx

import React, { useContext, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import {
  getCredits,
  deleteCredit,
  createCredit,
  updateCredit,
} from '../api/CreditService';
import {
  FaPen,
  FaTrash,
  FaPlus,
  FaBoxOpen,
  FaCopy,
  FaCalendarAlt,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import Header from '../components/Header';
import CreditModal from '../components/CreditModal';
import ConfirmModal from '../components/ConfirmModal';
import { IonSpinner } from '@ionic/react';
import { AuthContext, useAuth } from '../contexts/AuthContext';
import axios from '../axiosConfig';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Slider from 'react-slick';

// Importation des styles de react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { GrTransaction } from 'react-icons/gr';
import { updateProduct } from '../api/ProductService';
import { Pagination } from '@mui/material';

// Styles (iOS-like)
const Container = styled.div`
  padding: 20px;
  background-color: #f7f7f7;
  min-height: 100vh;
  font-family: 'San Francisco', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  padding-bottom: 20%;
`;

// Grid and list view containers
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  padding-bottom: 20%;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

// Empty state
const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  color: #888;
  font-size: 18px;
  text-align: center;
`;

const EmptyIcon = styled(FaBoxOpen)`
  font-size: 50px;
  margin-bottom: 15px;
  color: #ccc;
`;

const CreditIcon = styled(GrTransaction)`
  font-size: 50px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  color: #ccc;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const PaginationButton = styled.button`
  background-color: ${({ active }) => (active ? '#007aff' : '#fff')};
  color: ${({ active }) => (active ? '#fff' : '#007aff')};
  border: 1px solid #007aff;
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

// Credit card for grid view
const CreditCard = styled.div`
  background-color: #fff;
  padding: 15px 10px;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  margin-bottom: 15px;

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
`;

// Information inside each credit card
const CreditInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const CreditAmount = styled.h3`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
`;

const CreditTitle = styled.h2`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const CreditDate = styled.p`
  font-size: 12px;
  color: #666;
  position: absolute;
  right: 15px;
  bottom: 15px;
`;

// Action buttons (edit/delete/copy)
const ActionButtonGroup = styled.div`
  display: flex;
  gap: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

// ActionButton stylisé
const ActionButton = styled.button`
  background-color: ${({ color }) => color};
  color: #fff;
  border: none;
  padding: 8px;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;

  &:hover {
    background-color: ${({ hoverColor }) => hoverColor};
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

// Add credit button
const AddButton = styled.button`
  position: fixed;
  bottom: 60px;
  right: 20px;
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #005bb5;
    transform: scale(1.05);
  }
`;

// Rapports Administratifs (Nouveau Composant)
const ReportsContainerStyled = styled.div`
  margin-top: 40px;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

// Rapport Section Stylisé
const ReportSectionStyled = styled.div`
  background-color: #f2f2f7;
  padding: 15px;
  margin: 15px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ReportItem = styled.p`
  font-size: 14px;
  margin: 5px 0;
`;

// Titre pour les rapports
const ReportTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

// Rapport Header avec Icône de Calendrier
const ReportHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterButton = styled.button`
  background-color: ${({ active }) => (active ? '#007aff' : '#f1f1f1')};
  color: ${({ active }) => (active ? '#fff' : '#007aff')};
  border: none;
  border-radius: 15px;
  padding: 2vw 4vw;
  font-size: 14px;
  font-weight: 500;
  box-shadow: ${({ active }) =>
    active ? '0 4px 8px rgba(0, 0, 0, 0.2)' : '0 2px 4px rgba(0, 0, 0, 0.1)'};
  transition: all 0.3s ease;
  margin: 5px;
  cursor: pointer;
  min-width: 20vw;

  &:hover {
    background-color: ${({ active }) => (active ? '#005bb5' : '#e0e0e0')};
  }

  &:active {
    transform: scale(0.98);
  }
`;

const FilterButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2vh 0;
`;

// **Paramètres du slider**
const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
};

// Composant CreditList
const CreditList = () => {
  const [credits, setCredits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isCreditModalOpen, setIsCreditModalOpen] = useState(false);
  const [editingCreditId, setEditingCreditId] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [creditToDelete, setCreditToDelete] = useState(null);
  const [viewMode, setViewMode] = useState('grid');
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const handleStatusChange = (status) => setStatusFilter(status);

  // Filtres par date
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateFilterMode, setDateFilterMode] = useState('range');

  const { auth, setAuth } = useAuth();
  const { user } = auth || {};
  const authorizedCategories = user?.authorizedCategories || [];
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 20;

  // Fetching credits
  const fetchCredits = useCallback(async () => {
  try {
    const response = await axios.get('/api/credits', {
      params: {
        Pagination: false,
        populate: ['user', 'product', 'product.category'], // Ajout de 'product' et 'product.category'
      },
    });

    const data = response.data['hydra:member'] || response.data.member || [];
    setCredits(data);
    credits.forEach((credit) => {
      console.log('Credit ID:', credit.id);
      console.log('Credit Product:', credit.product);
      console.log('Credit Product Category:', credit.product?.category);
      console.log('Credit Product Category ID:', credit.product?.category?.id);
    });
    
    // Calcul des totaux
    const totalItems = response.data['hydra:totalItems'] || response.data.totalItems || 0;
    const calculatedTotalPages = Math.ceil(totalItems / itemsPerPage);
    setTotalPages(calculatedTotalPages);
    setTotalItems(totalItems);
  } catch (error) {
    console.error('Erreur lors de la récupération des crédits:', error);
  } finally {
    setLoading(false);
  }
}, [currentPage]);

  // Déterminer si l'utilisateur est administrateur
  const isAdmin = user && user.roles && user.roles.includes('ROLE_ADMIN');

  // Polling pour les catégories autorisées
  useEffect(() => {
    console.log("Rôles de l'utilisateur :", user?.roles);

    const pollCategories = async () => {
      try {
        const response = await axios.get('/check');
        setAuth((prevAuth) => ({
          ...prevAuth,
          user: {
            ...prevAuth.user,
            authorizedCategories: response.data.authorizedCategories,
          },
        }));
        fetchCredits();
      } catch (error) {
        console.error('Erreur lors du polling des catégories :', error);
      }
    };

    const interval = setInterval(pollCategories, 5000);

    return () => clearInterval(interval);
  }, [setAuth, fetchCredits]);

  const handleCreditSubmit = async (creditData) => {
    try {
      if (!creditData.user && user?.id) {
        creditData.user = `/api/users/${user.id}`;
      }

      const formattedData = {
        ...creditData,
        title: String(creditData.title),
        amount: String(creditData.totalAmount),
      };

      if (editingCreditId) {
        await updateCredit(editingCreditId, formattedData);
        toast.success('Crédit mis à jour avec succès.');
      } else {
        await createCredit(formattedData);
        toast.success('Crédit créé avec succès.');
      }

      closeCreditModal();
      fetchCredits();
    } catch (error) {
      console.error('Erreur lors de la soumission du crédit :', error);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`Erreur : ${error.response.data.message}`);
      } else {
        toast.error('Erreur lors de la soumission du crédit.');
      }
    }
  };

  useEffect(() => {
    console.log('authorizedCategories:', authorizedCategories);
  }, [authorizedCategories]);

  useEffect(() => {
    fetchCredits();
  }, [fetchCredits, currentPage]);

  const openCreditModal = (creditId = null) => {
    setEditingCreditId(creditId);
    setIsCreditModalOpen(true);
  };

  const closeCreditModal = () => {
    setEditingCreditId(null);
    setIsCreditModalOpen(false);
    fetchCredits();
  };

  const openConfirmModal = (creditId) => {
    setCreditToDelete(creditId);
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setCreditToDelete(null);
    setIsConfirmModalOpen(false);
  };

  const confirmDelete = async () => {
    if (creditToDelete) {
      try {
        await deleteCredit(creditToDelete);
        setCredits(credits.filter((cr) => cr.id !== creditToDelete));
        setCreditToDelete(null);
        closeConfirmModal();
        toast.success('Crédit supprimé avec succès.');
      } catch (error) {
        console.error('Erreur lors de la suppression du crédit:', error);
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(`Erreur : ${error.response.data.message}`);
        } else {
          toast.error('Erreur lors de la suppression du crédit.');
        }
      }
    }
  };

  const handleCopy = (credit) => {
    const creditString = `
ID: ${credit.id}
Utilisateur ID: ${credit.user?.id || 'Inconnu'}
Utilisateur Email: ${credit.user?.email || 'Inconnu'}
Montant: ${credit.totalAmount} €
Titre: ${credit.title}
Créé le: ${new Date(credit.createdAt).toLocaleString()}
Mis à jour le: ${
      credit.updatedAt
        ? new Date(credit.updatedAt).toLocaleString()
        : 'Non mis à jour'
    }
    `;
    navigator.clipboard
      .writeText(creditString)
      .then(() => {
        toast.success('Crédit copié dans le presse-papiers.');
      })
      .catch(() => {
        toast.error('Erreur lors de la copie du crédit.');
      });
  };

  // Calcul des montants de crédits par statut
  const calculateAmounts = () => {
    const amounts = {
      pending: 0,
      completed: 0,
      cancelled: 0,
    };

    credits.forEach((cr) => {
      if (cr.status === 'Pending') {
        amounts.pending += parseFloat(cr.totalAmount) || 0;
      } else if (cr.status === 'Completed') {
        amounts.completed += parseFloat(cr.totalAmount) || 0;
      } else if (cr.status === 'Cancelled') {
        amounts.cancelled += parseFloat(cr.totalAmount) || 0;
      }
    });

    return amounts;
  };

  const amounts = calculateAmounts();

  // Rapports par jour et par mois
  const generateDailyReports = () => {
    const reportsArray = [];
    const reports = {};
    credits.forEach((cr) => {
      const date = new Date(cr.createdAt);
      const day = date.toLocaleDateString('fr-FR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      if (!reports[day]) {
        reports[day] = {
          date: day,
          dateObj: date,
          credits: 0,
          totalAmount: 0,
        };
      }

      reports[day].credits += 1;
      reports[day].totalAmount += parseFloat(cr.totalAmount) || 0;
    });

    Object.values(reports)
      .sort((a, b) => b.dateObj - a.dateObj)
      .forEach((report) => reportsArray.push(report));

    return reportsArray;
  };

  const generateMonthlyReports = () => {
    const reportsArray = [];
    const reports = {};
    credits.forEach((cr) => {
      const date = new Date(cr.createdAt);
      const month = date.toLocaleString('fr-FR', {
        month: 'long',
        year: 'numeric',
      });

      if (!reports[month]) {
        reports[month] = {
          month: month,
          dateObj: new Date(date.getFullYear(), date.getMonth(), 1),
          credits: 0,
          totalAmount: 0,
        };
      }

      reports[month].credits += 1;
      reports[month].totalAmount += parseFloat(cr.totalAmount) || 0;
    });

    Object.values(reports)
      .sort((a, b) => b.dateObj - a.dateObj)
      .forEach((report) => reportsArray.push(report));

    return reportsArray;
  };

  const dailyReportsArray = generateDailyReports();
  const monthlyReportsArray = generateMonthlyReports();

  const filteredCredits = credits
  .filter((credit) => {
    // Filtrage par recherche
    const matchesSearch = (credit.totalAmount?.toString() || '')
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    // Filtrage par date
    const creditDate = new Date(credit.createdAt);
    let matchesDate = true;

    if (dateFilterMode === 'single' && startDate) {
      matchesDate = creditDate.toDateString() === startDate.toDateString();
    } else if (dateFilterMode === 'range' && startDate && endDate) {
      matchesDate = creditDate >= startDate && creditDate <= endDate;
    } else if (dateFilterMode === 'month' && startDate) {
      matchesDate =
        creditDate.getMonth() === startDate.getMonth() &&
        creditDate.getFullYear() === startDate.getFullYear();
    }

    // Filtrage par statut
    const matchesStatus =
      statusFilter === 'All' || credit.status === statusFilter;

    // Filtrage par catégories autorisées pour les utilisateurs non administrateurs
    let isAuthorized = true;
    if (!isAdmin) {
      const categoryId = credit.product?.category?.id;
      console.log('Credit ID:', credit.id);
      console.log('Credit Product Category ID:', categoryId);
      console.log(
        'Authorized Categories IDs:',
        authorizedCategories.map((cat) => cat.id)
      );

      if (categoryId) {
        isAuthorized = authorizedCategories.some(
          (cat) => String(cat.id) === String(categoryId)
        );
      } else {
        console.log('Crédit sans catégorie ou sans produit :', credit);
        isAuthorized = false;
      }
    }

    return matchesSearch && matchesDate && matchesStatus && isAuthorized;
  })
  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));


  const getAmount = (amount) => (isNaN(amount) ? 0 : amount);

  const translateStatus = (status) => {
    switch (status) {
      case 'Pending':
        return 'En attente';
      case 'Completed':
        return 'Terminé';
      case 'Cancelled':
        return 'Annulé';
      default:
        return status;
    }
  };

  const PaginationControls = () => (
    <PaginationContainer>
      <PaginationButton
        disabled={currentPage === 1}
        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
      >
        Précédent
      </PaginationButton>
      {Array.from({ length: totalPages }, (_, index) => (
        <PaginationButton
          key={index + 1}
          active={currentPage === index + 1}
          onClick={() => setCurrentPage(index + 1)}
        >
          {index + 1}
        </PaginationButton>
      ))}
      <PaginationButton
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
      >
        Suivant
      </PaginationButton>
    </PaginationContainer>
  );

  return (
    <Container style={{ paddingBottom: 80 }}>
      <Header
        title="Crédits"
        onSearch={setSearchQuery}
        onToggleView={setViewMode}
        viewMode={viewMode}
      />

      {isAdmin && (
        <ReportsContainerStyled style={{ marginBottom: 50 }}>
          <ReportTitle style={{ fontSize: 25, textAlign: 'center' }}>
            Crédits
          </ReportTitle>
          <CreditIcon style={{ textAlign: 'center', margin: 'auto' }} />

          <ReportSectionStyled>
            <ReportTitle>Montants</ReportTitle>
            <ReportItem>
              <strong>En attente :</strong> {amounts.pending.toFixed(2)} €
            </ReportItem>
            <ReportItem>
              <strong>Terminé :</strong> {amounts.completed.toFixed(2)} €
            </ReportItem>
            <ReportItem>
              <strong>Annulé :</strong> {amounts.cancelled.toFixed(2)} €
            </ReportItem>
          </ReportSectionStyled>

          {/* Rapport Quotidien */}
          <ReportSectionStyled>
            <ReportTitle>Rapports Quotidiens</ReportTitle>
            <hr />
            {dailyReportsArray.length === 0 ? (
              <p>Aucun rapport disponible.</p>
            ) : (
              <Slider {...sliderSettings}>
                {dailyReportsArray.map((report, index) => (
                  <ReportSectionStyled key={index}>
                    <ReportHeader>
                      <ReportTitle>Rapport du {report.date}</ReportTitle>
                    </ReportHeader>
                    <ReportItem>
                      <strong>Crédits :</strong> {report.credits}
                    </ReportItem>
                    <ReportItem>
                      <strong>Montant Total :</strong>{' '}
                      {report.totalAmount.toFixed(2)} €
                    </ReportItem>
                  </ReportSectionStyled>
                ))}
              </Slider>
            )}
          </ReportSectionStyled>

          {/* Rapport Mensuel */}
          <ReportSectionStyled>
            <ReportTitle>Rapports Mensuels</ReportTitle>
            <hr />
            {monthlyReportsArray.length === 0 ? (
              <p>Aucun rapport disponible.</p>
            ) : (
              <Slider {...sliderSettings}>
                {monthlyReportsArray.map((report, index) => (
                  <ReportSectionStyled key={index}>
                    <ReportHeader>
                      <ReportTitle>Rapport de {report.month}</ReportTitle>
                    </ReportHeader>
                    <ReportItem>
                      <strong>Crédits :</strong> {report.credits}
                    </ReportItem>
                    <ReportItem>
                      <strong>Montant Total :</strong>{' '}
                      {report.totalAmount.toFixed(2)} €
                    </ReportItem>
                  </ReportSectionStyled>
                ))}
              </Slider>
            )}
          </ReportSectionStyled>
        </ReportsContainerStyled>
      )}

      {loading ? (
        <EmptyState>
          <IonSpinner name="crescent" />
          <p>Chargement des crédits...</p>
        </EmptyState>
      ) : filteredCredits.length === 0 ? (
        <EmptyState>
          <EmptyIcon />
          <p>Aucun crédit disponible.</p>
          <FilterButtonContainer>
            <FilterButton
              active={statusFilter === 'All'}
              onClick={() => handleStatusChange('All')}
            >
              Tous
            </FilterButton>
            <FilterButton
              active={statusFilter === 'Pending'}
              onClick={() => handleStatusChange('Pending')}
            >
              En attente
            </FilterButton>
            <FilterButton
              active={statusFilter === 'Completed'}
              onClick={() => handleStatusChange('Completed')}
            >
              Terminé
            </FilterButton>
            <FilterButton
              active={statusFilter === 'Cancelled'}
              onClick={() => handleStatusChange('Cancelled')}
            >
              Annulé
            </FilterButton>
          </FilterButtonContainer>
        </EmptyState>
      ) : (
        <>
          <FilterButtonContainer>
            <FilterButton
              active={statusFilter === 'All'}
              onClick={() => handleStatusChange('All')}
            >
              Tous
            </FilterButton>
            <FilterButton
              active={statusFilter === 'Pending'}
              onClick={() => handleStatusChange('Pending')}
            >
              En attente
            </FilterButton>
            <FilterButton
              active={statusFilter === 'Completed'}
              onClick={() => handleStatusChange('Completed')}
            >
              Terminé
            </FilterButton>
            <FilterButton
              active={statusFilter === 'Cancelled'}
              onClick={() => handleStatusChange('Cancelled')}
            >
              Annulé
            </FilterButton>
          </FilterButtonContainer>

          {totalItems <= 20 && <PaginationControls />}

          {viewMode === 'grid' ? (
            <GridContainer>
              {filteredCredits.map((credit) => (
                <CreditCard
                  key={credit.id}
                  style={{ padding: '55px 25px', paddingTop: 50 }}
                >
                  <CreditInfo>
                    <CreditTitle>{credit.title}</CreditTitle>
                    <CreditAmount>Montant : {credit.totalAmount} €</CreditAmount>
                    <CreditAmount>
                      Réalisé par : {credit.user?.username || 'Inconnu'}
                    </CreditAmount>
                    <CreditAmount>
                      Statut : {translateStatus(credit.status)}
                    </CreditAmount>
                  </CreditInfo>
                  <CreditDate>
                    {new Date(credit.createdAt).toLocaleDateString()}
                  </CreditDate>
                  <ActionButtonGroup>
                    {isAdmin && (
                      <>
                        <ActionButton
                          color="#34C759"
                          hoverColor="#2DA647"
                          onClick={() => openCreditModal(credit.id)}
                          aria-label="Modifier le crédit"
                        >
                          <FaPen />
                        </ActionButton>
                        <ActionButton
                          color="#FF3B30"
                          hoverColor="#D9291C"
                          onClick={() => openConfirmModal(credit.id)}
                          aria-label="Supprimer le crédit"
                        >
                          <FaTrash />
                        </ActionButton>
                      </>
                    )}
                    <ActionButton
                      color="#007AFF"
                      hoverColor="#005bb5"
                      onClick={() => handleCopy(credit)}
                      aria-label="Copier le crédit"
                    >
                      <FaCopy />
                    </ActionButton>
                  </ActionButtonGroup>
                </CreditCard>
              ))}
            </GridContainer>
          ) : (
            <ListContainer>
              {filteredCredits.map((credit) => (
                <CreditCard key={credit.id}>
                  <CreditInfo>
                    <CreditTitle>{credit.title}</CreditTitle>
                    <CreditAmount>Montant : {credit.totalAmount} €</CreditAmount>
                    <CreditAmount>
                      Réalisé par : {credit.user?.username || 'Inconnu'}
                    </CreditAmount>
                    <CreditAmount>
                      Statut : {translateStatus(credit.status)}
                    </CreditAmount>
                  </CreditInfo>
                  <CreditDate>
                    {new Date(credit.createdAt).toLocaleDateString()}
                  </CreditDate>
                  <ActionButtonGroup>
                    {isAdmin && (
                      <>
                        <ActionButton
                          color="#34C759"
                          hoverColor="#2DA647"
                          onClick={() => openCreditModal(credit.id)}
                          aria-label="Modifier le crédit"
                        >
                          <FaPen />
                        </ActionButton>
                        <ActionButton
                          color="#FF3B30"
                          hoverColor="#D9291C"
                          onClick={() => openConfirmModal(credit.id)}
                          aria-label="Supprimer le crédit"
                        >
                          <FaTrash />
                        </ActionButton>
                      </>
                    )}
                    <ActionButton
                      color="#007AFF"
                      hoverColor="#005bb5"
                      onClick={() => handleCopy(credit)}
                      aria-label="Copier le crédit"
                    >
                      <FaCopy />
                    </ActionButton>
                  </ActionButtonGroup>
                </CreditCard>
              ))}
            </ListContainer>
          )}
        </>
      )}

        <AddButton
          onClick={() => openCreditModal(null)}
          aria-label="Ajouter un crédit"
        >
          <FaPlus style={{ fontSize: 20 }} />
        </AddButton>

      {/* Modale pour ajouter/modifier un crédit */}
      <CreditModal
        isOpen={isCreditModalOpen}
        onRequestClose={closeCreditModal}
        onSubmit={handleCreditSubmit}
        creditData={
          editingCreditId
            ? credits.find((cr) => cr.id === editingCreditId)
            : null
        }
        userId={user?.id}
      />

      {/* Modale de confirmation pour supprimer un crédit */}
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onRequestClose={closeConfirmModal}
        onConfirm={confirmDelete}
        title="Confirmer la Suppression"
        message="Êtes-vous sûr de vouloir supprimer ce crédit ?"
      />
    </Container>
  );
};

export default CreditList;
