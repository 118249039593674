import React, { useState } from 'react';
import styled from 'styled-components';
import { FaSearch, FaTh, FaList, FaSort } from 'react-icons/fa';
import { Shop2 } from '@mui/icons-material';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 1000;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 85%;
  margin-left: 35px;
  margin-right: auto;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  flex: 1;
  font-size: 16px;
  padding-left: 10px;
`;

const SearchIcon = styled(FaSearch)`
  color: #888;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 35vh;
  font-size: 15px;
  margin-left:5px;
`;

const TitlePageSection = styled.div`
  padding-right:5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
  box-shadow: none;
  background-color: transparent;
  flex-direction: row;
  padding: 8px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
`;

const ToggleViewButton = styled.button`
  background-color: #fff;
  border: none;
  padding: 10px;
  border-radius: 8px;
  font-size: 18px;
  color: #007aff;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 5px;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const SortButton = styled.button`
  background-color: #fff;
  border: none;
  padding: 10px;
  border-radius: 8px;
  font-size: 18px;
  color: #007aff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #f1f1f1;
  }
`;

const SortMenu = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 10px;
  z-index: 1001;
`;

const SortOption = styled.div`
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  color: #333;

  &:hover {
    background-color: #f1f1f1;
    border-radius: 8px;
  }
`;

const Header = ({ pageType, title, onSearch, onToggleView, viewMode, onSortChange }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSortMenuOpen, setIsSortMenuOpen] = useState(false);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    if (onSearch) {
      onSearch(e.target.value);
    }
  };

  const toggleSortMenu = () => {
    setIsSortMenuOpen(!isSortMenuOpen);
  };

  const handleSortChange = (sortOption) => {
    onSortChange(sortOption);
    setIsSortMenuOpen(false); // Fermer le menu une fois qu'une option est sélectionnée
  };

  // Options de tri, s'assurer que chaque pageType a des options valides
  const sortOptions = {
    products: ['Trier par Nom', 'Trier par Prix'],
    transactions: ['Trier par Date', 'Trier par Montant'],
    users: ['Trier par Nom', 'Trier par Email'],
    categories: ['Trier par Nom']
  };

  const availableSortOptions = sortOptions[pageType] || [];

  return (
    <>
      <HeaderContainer>
        <LeftSection>
          <SearchBarContainer>
            <SearchIcon />
            <SearchInput
              type="text"
              placeholder={`Rechercher dans ${title}...`}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </SearchBarContainer>
        </LeftSection>

        <RightSection>
          <SortButton onClick={toggleSortMenu}>
            <FaSort />
          </SortButton>

          {isSortMenuOpen && (
            <SortMenu>
              {availableSortOptions.map((option, index) => (
                <SortOption key={index} onClick={() => handleSortChange(option)}>
                  {option}
                </SortOption>
              ))}
            </SortMenu>
          )}

          <ToggleViewButton onClick={() => onToggleView(viewMode === 'grid' ? 'list' : 'grid')}>
            {viewMode === 'grid' ? <FaTh /> : <FaList />}
          </ToggleViewButton>

          <TitlePageSection>
            <Shop2 style={{ fontSize: '20px', marginRight: '8px', color: '#007aff' }} />
            {title}
          </TitlePageSection>
        </RightSection>
      </HeaderContainer>
    </>
  );
};

export default Header;
