import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getUsers, deleteUser } from '../api/UserService'; // Assure-toi que l'API est bien configurée pour récupérer les utilisateurs
import { FaPen, FaTrash, FaPlus, FaUser } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Header from '../components/Header';
import UserModal from '../components/UserModal';
import ConfirmModal from '../components/ConfirmModal';
import { IonSpinner } from '@ionic/react';

// Conteneur global
const Container = styled.div`
  padding: 20px;
  background-color: #f7f7f7;
  min-height: 100vh;
  font-family: ${({ theme }) => theme.fonts.regular};
`;
// List Item de produit (affichage en liste)
const ProductListItem = styled.div`
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;
// Grille pour l'affichage en cartes
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Deux cartes par ligne */
  gap: 20px;
`;

// Liste pour l'affichage en liste
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

// Carte d'utilisateur (affichage en grille)
const UserCard = styled.div`
  background-color: #fff;
  padding: 15px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
`;

// Informations sur l'utilisateur
const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const UserName = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
`;

const UserEmail = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
`;

// Groupe de boutons d'action avec icônes réduits
const ActionButtonGroup = styled.div`
  display: flex;
  gap: 5px;
`;

const ActionButton = styled.button`
  background-color: ${({ color }) => color};
  color: #fff;
  border: none;
  padding: 6px;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    background-color: ${({ hoverColor }) => hoverColor};
  }

  svg {
    font-size: 14px;
  }
`;

// Empty state
const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  color: #888;
  font-size: 18px;
  text-align: center;
`;

const EmptyIcon = styled(FaUser)`
  font-size: 50px;
  margin-bottom: 15px;
  color: #ccc;
`;

// Bouton d'ajout d'utilisateur
const AddButton = styled.button`
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease, transform 0.2s ease;
  z-index: 1001;

  &:hover {
    background-color: #005bb5;
    transform: scale(1.05);
  }
`;

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [editingUserId, setEditingUserId] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [viewMode, setViewMode] = useState('grid'); // Gestion du mode d'affichage

  const fetchUsers = async () => {
    try {
      const data = await getUsers(); // Récupère les utilisateurs depuis l'API
      setUsers(data['hydra:member'] || data.member || []);
    } catch (error) {
      toast.error('Erreur lors de la récupération des utilisateurs');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const openUserModal = (userId = null) => {
    setEditingUserId(userId);
    setIsUserModalOpen(true);
  };

  const closeUserModal = () => {
    setEditingUserId(null);
    setIsUserModalOpen(false);
    fetchUsers(); // Refresh user list after modal close
    toast.success(editingUserId ? 'Utilisateur modifié avec succès' : 'Utilisateur créé avec succès');
  };

  const openConfirmModal = (userId) => {
    setUserToDelete(userId);
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setUserToDelete(null);
    setIsConfirmModalOpen(false);
  };

  const confirmDelete = async () => {
    if (userToDelete) {
      try {
        await deleteUser(userToDelete); // Suppression de l'utilisateur
        setUsers(users.filter((user) => user.id !== userToDelete));
        setUserToDelete(null);
        closeConfirmModal();
        toast.success('Utilisateur supprimé avec succès');
      } catch (error) {
        toast.error('Erreur lors de la suppression de l\'utilisateur');
      }
    }
  };

  if (loading) {
    return (
      <Container>
        <Header title="Utilisateurs" />
        <EmptyState>
          <IonSpinner />
          <p>Chargement des utilisateurs...</p>
        </EmptyState>
      </Container>
    );
  }

  return (
    <Container>
      <Header
        title="Utilisateurs"
        onToggleView={setViewMode}
        viewMode={viewMode}
      />

      {users.length === 0 ? (
        <EmptyState>
          <EmptyIcon />
          <p>Aucun utilisateur disponible.</p>
        </EmptyState>
      ) : viewMode === 'grid' ? (
        <GridContainer>
          {users.map((user) => (
            <UserCard key={user.id}>
              <UserInfo>
                <UserName>{user.name}</UserName>
                <UserEmail>{user.email}</UserEmail>
              </UserInfo>
              <ActionButtonGroup>
                <ActionButton
                  color="#34C759"
                  hoverColor="#2DA647"
                  onClick={() => openUserModal(user.id)}
                >
                  <FaPen />
                </ActionButton>
                <ActionButton
                  color="#FF3B30"
                  hoverColor="#D9291C"
                  onClick={() => openConfirmModal(user.id)}
                >
                  <FaTrash />
                </ActionButton>
              </ActionButtonGroup>
            </UserCard>
          ))}
        </GridContainer>
      ) : (
        <ListContainer>
          {users.map((user) => (
            <ProductListItem key={user.id}>
              <UserInfo>
                <UserName>{user.name}</UserName>
                <UserEmail>{user.email}</UserEmail>
              </UserInfo>
              <ActionButtonGroup>
                <ActionButton
                  color="#34C759"
                  hoverColor="#2DA647"
                  onClick={() => openUserModal(user.id)}
                >
                  <FaPen />
                </ActionButton>
                <ActionButton
                  color="#FF3B30"
                  hoverColor="#D9291C"
                  onClick={() => openConfirmModal(user.id)}
                >
                  <FaTrash />
                </ActionButton>
              </ActionButtonGroup>
            </ProductListItem>
          ))}
        </ListContainer>
      )}

      <AddButton onClick={() => openUserModal(null)} aria-label="Ajouter un utilisateur">
        <FaPlus />
      </AddButton>

      <UserModal
        isOpen={isUserModalOpen}
        onRequestClose={closeUserModal}
        userId={editingUserId}
      />

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onRequestClose={closeConfirmModal}
        onConfirm={confirmDelete}
        title="Confirmer la Suppression"
        message="Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
      />
    </Container>
  );
};

export default UserList;
