import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaTimes, FaHome, FaChartBar, FaUser, FaCog, FaLock, FaBoxOpen, FaBox, FaUsers, FaListAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import { useAuth } from '../contexts/AuthContext'; // Assurez-vous d'importer le contexte d'authentification
import { GrTransaction } from 'react-icons/gr';

const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #007aff;
`;

// Animation for the menu opening
const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Animation for the menu closing
const slideOut = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
`;

// The translucent background overlay
const Overlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  transition: opacity 0.3s ease-in-out;
`;

// Menu styles
const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  max-width: 320px;
  height: 100%;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.75));
  box-shadow: 2px 0 12px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  z-index: 1000;
  animation: ${({ isOpen }) => (isOpen ? slideIn : slideOut)} 0.4s ease forwards;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-left: 20px;
`;

// Close icon styles
const CloseIcon = styled(FaTimes)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: #333;
  font-size: 24px;
  transition: color 0.3s ease;

  &:hover {
    color: #007AFF;
  }
`;

// Menu list styles
const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 20px;
`;

// Menu item styles
const MenuItem = styled.li`
  margin-bottom: 30px;
  font-size: 18px;
  transition: all 0.3s ease;
`;

// Menu link styles
const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 18px;
  text-decoration: none;
  padding: 15px 10px;
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 10px;

  &:hover {
    color: rgba(0, 0, 0, 1);
    background-color: rgba(0, 122, 255, 0.2);
  }

  svg {
    margin-right: 15px;
    font-size: 20px;
  }
`;

const HamburgerMenu = ({ isOpen, toggleMenu, toggleLock }) => {
  const { auth } = useAuth(); // Récupérer le contexte d'authentification
  const { user } = auth || {}; // Assurez-vous que user est bien récupéré

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (isOpen) toggleMenu();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <>
      <Overlay isOpen={isOpen} onClick={toggleMenu} aria-hidden={!isOpen} />
      <Menu isOpen={isOpen} aria-label="Menu principal" {...handlers}>
        <CloseIcon size={24} onClick={toggleMenu} aria-label="Fermer le menu" />
        <MenuList>
          <MenuItem>
            <MenuLink to="/" onClick={toggleMenu}>
              <FaHome />
              Accueil
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to="/dashboard" onClick={toggleMenu}>
              <FaChartBar />
              Dashboard
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to="/transactions" onClick={toggleMenu}>
              <GrTransaction />
              Transactions
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to="/credit" onClick={toggleMenu}>
              <GrTransaction />
              Crédits
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to="/products" onClick={toggleMenu}>
              <FaBox />
              Produits
            </MenuLink>
          </MenuItem>
          {/* Affichez Utilisateurs et Catégories uniquement pour les administrateurs */}
          {user?.roles.includes('ROLE_ADMIN') && (
            <>
              <MenuItem>
                <MenuLink to="/users" onClick={toggleMenu}>
                  <FaUsers />
                  Utilisateurs
                </MenuLink>
              </MenuItem>
              <MenuItem>
                <MenuLink to="/categories" onClick={toggleMenu}>
                  <FaListAlt />
                  Catégories
                </MenuLink>
              </MenuItem>
            </>
          )}
          
          <MenuItem>
            <MenuLink to="/settings" onClick={toggleMenu}>
              <FaCog />
              Paramètres
            </MenuLink>
          </MenuItem>
          {/* <MenuButton onClick={toggleLock}>
            <FaLock /> Verrouiller
          </MenuButton> */}
        </MenuList>
      </Menu>
    </>
  );
};

HamburgerMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  toggleLock: PropTypes.func.isRequired,
};

export default HamburgerMenu;
