// src/api/ProductService.js
import api from '../axiosConfig';

// Récupérer la liste des produits
export const getCredits = async () => {
  try {
    const response = await api.get('/api/credits');
    // Adapter selon la structure des données
    console.log(response.data)
    return response.data['hydra:member'] || response.data;
  } catch (error) {
    console.error('Erreur dans credit:', error);
    throw error;
  }
};

// Récupérer un seul produit
export const getCredit = async (id) => {
  try {
    const response = await api.get(`/api/credits/${id}`);
    return response.data;
  } catch (error) {
    console.error('Erreur dans getProduct:', error);
    throw error;
  }
};

// Créer un nouveau produit
export const createCredit = async (creditData) => {
  try {
    // Adapter les données au format JSON-LD
    const jsonLdData = {
      "@context": "/api/contexts/Credit",
      "@type": "Credit",
      ...creditData,
    };

    const response = await api.post('/api/credits', jsonLdData, {
      headers: {
        'Content-Type': 'application/ld+json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Erreur dans createProduct:', error);
    throw error;
  }
};

// Mettre à jour un produit existant
export const updateCredit = async (id, creditData) => {
  try {
    // Adapter les données au format JSON-LD
    const jsonLdData = {
      "@context": `/api/contexts/Credit/${id}`,
      "@type": "Credit",
      ...creditData,
    };

    // Utilisez PATCH et le bon type MIME "application/merge-patch+json"
    const response = await api.patch(`/api/credits/${id}`, jsonLdData, {
      headers: {
        'Content-Type': 'application/merge-patch+json', // Le type MIME attendu par l'API
      },
    });
    return response.data;
  } catch (error) {
    console.error('Erreur dans updatecredit:', error);
    throw error;
  }
};


// Supprimer un produit
export const deleteCredit = async (id) => {
  try {
    await api.delete(`/api/credits/${id}`);
  } catch (error) {
    console.error('Erreur dans deletecredit:', error);
    throw error;
  }
};
