// src/components/Layout.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import BottomBar from './BottomBar';
import HamburgerMenu from './HamburgerMenu';
import HamburgerButton from './HamburgerButton';

const Layout = ({ children, isMenuOpen, toggleMenu }) => {
  const location = useLocation();

  // Liste des routes où la navigation doit être masquée
  const hiddenNavRoutes = ['/login'];

  // Conditionner l'affichage de la navigation
  const shouldHideNav = hiddenNavRoutes.includes(location.pathname);

  return (
    <>
      {!shouldHideNav && <HamburgerButton toggleMenu={toggleMenu} />}
      {!shouldHideNav && <HamburgerMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />}
      {children}
      {!shouldHideNav && <BottomBar />}
    </>
  );
};

export default Layout;
