import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getCategories, deleteCategory } from '../api/CategoryService';
import { FaPen, FaTrash, FaPlus, FaBoxOpen } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Header from '../components/Header';
import CategoryModal from '../components/CategoryModal';
import ConfirmModal from '../components/ConfirmModal';
import { IonSpinner } from '@ionic/react';

// Conteneur global
const Container = styled.div`
  padding: 20px;
  background-color: #f7f7f7;
  min-height: 100vh;
  font-family: ${({ theme }) => theme.fonts.regular};
`;

// Grille pour l'affichage en cartes
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); 
  gap: 20px;
`;

// Liste pour l'affichage en liste
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

// List Item de catégorie (affichage en liste)
const CategoryListItem = styled.div`
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

// Cartes de catégorie
const CategoryCard = styled.div`
  background-color: #fff;
  padding: 15px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
`;

// Informations sur la catégorie
const CategoryInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const CategoryName = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
`;

// Groupe de boutons d'action avec icônes réduits
const ActionButtonGroup = styled.div`
  display: flex;
  gap: 5px;
`;

const ActionButton = styled.button`
  background-color: ${({ color }) => color};
  color: #fff;
  border: none;
  padding: 6px;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    background-color: ${({ hoverColor }) => hoverColor};
  }

  svg {
    font-size: 14px;
  }
`;

// Empty state
const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  color: #888;
  font-size: 18px;
  text-align: center;
`;

const EmptyIcon = styled(FaBoxOpen)`
  font-size: 50px;
  margin-bottom: 15px;
  color: #ccc;
`;

// Bouton d'ajout de catégorie
const AddButton = styled.button`
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease, transform 0.2s ease;
  z-index: 1001;

  &:hover {
    background-color: #005bb5;
    transform: scale(1.05);
  }
`;

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [editingCategoryId, setEditingCategoryId] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [viewMode, setViewMode] = useState('grid'); // Ajout de la gestion de la vue
  const [searchQuery, setSearchQuery] = useState(''); // Ajout de la gestion de la recherche

  const fetchCategories = async () => {
    try {
      const data = await getCategories();
      setCategories(data['hydra:member'] || data.member || []);
    } catch (error) {
      toast.error('Erreur lors de la récupération des catégories');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const openCategoryModal = (categoryId = null) => {
    setEditingCategoryId(categoryId);
    setIsCategoryModalOpen(true);
  };

  const closeCategoryModal = () => {
    setEditingCategoryId(null);
    setIsCategoryModalOpen(false);
    fetchCategories();
  };

  const openConfirmModal = (categoryId) => {
    setCategoryToDelete(categoryId);
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setCategoryToDelete(null);
    setIsConfirmModalOpen(false);
  };

  const confirmDelete = async () => {
    if (categoryToDelete) {
      try {
        await deleteCategory(categoryToDelete);
        setCategories(categories.filter((category) => category.id !== categoryToDelete));
        setCategoryToDelete(null);
        closeConfirmModal();
        toast.success('Catégorie supprimée avec succès');
      } catch (error) {
        toast.error('Erreur lors de la suppression de la catégorie');
      }
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    // Filtrer les catégories selon le nom avec la recherche
    const filtered = categories.filter((category) => category.name.toLowerCase().includes(query.toLowerCase()));
    setCategories(filtered);
  };

  const handleToggleView = () => {
    setViewMode((prevMode) => (prevMode === 'grid' ? 'list' : 'grid'));
  };

  const handleSortChange = (sortOption) => {
    if (sortOption === 'name') {
      setCategories([...categories].sort((a, b) => a.name.localeCompare(b.name)));
    }
  };

  if (loading) {
    return (
      <Container>
        <Header
          pageType="categories"
          title="Catégories"
          onSearch={handleSearch}
          onToggleView={handleToggleView}
          viewMode={viewMode}
          onSortChange={handleSortChange}
        />
        <EmptyState>
          <IonSpinner />
          <p>Chargement des catégories...</p>
        </EmptyState>
      </Container>
    );
  }

  return (
    <Container>
      <Header
        pageType="categories"
        title="Catégories"
        onSearch={handleSearch}
        onToggleView={handleToggleView}
        viewMode={viewMode}
        onSortChange={handleSortChange}
      />

      {categories.length === 0 ? (
        <EmptyState>
          <EmptyIcon />
          <p>Aucune catégorie disponible.</p>
        </EmptyState>
      ) : viewMode === 'grid' ? (
        <GridContainer>
          {categories.map((category) => (
            <CategoryCard key={category.id}>
              <CategoryInfo>
                <CategoryName>{category.name}</CategoryName>
              </CategoryInfo>
              <ActionButtonGroup>
                <ActionButton
                  color="#34C759"
                  hoverColor="#2DA647"
                  onClick={() => openCategoryModal(category.id)}
                >
                  <FaPen />
                </ActionButton>
                <ActionButton
                  color="#FF3B30"
                  hoverColor="#D9291C"
                  onClick={() => openConfirmModal(category.id)}
                >
                  <FaTrash />
                </ActionButton>
              </ActionButtonGroup>
            </CategoryCard>
          ))}
        </GridContainer>
      ) : (
        <ListContainer>
          {categories.map((category) => (
            <CategoryListItem key={category.id}>
              <CategoryInfo>
                <CategoryName>{category.name}</CategoryName>
              </CategoryInfo>
              <ActionButtonGroup>
                <ActionButton
                  color="#34C759"
                  hoverColor="#2DA647"
                  onClick={() => openCategoryModal(category.id)}
                >
                  <FaPen />
                </ActionButton>
                <ActionButton
                  color="#FF3B30"
                  hoverColor="#D9291C"
                  onClick={() => openConfirmModal(category.id)}
                >
                  <FaTrash />
                </ActionButton>
              </ActionButtonGroup>
            </CategoryListItem>
          ))}
        </ListContainer>
      )}

      <AddButton onClick={() => openCategoryModal(null)} aria-label="Ajouter une catégorie">
        <FaPlus />
      </AddButton>

      <CategoryModal
        isOpen={isCategoryModalOpen}
        onRequestClose={closeCategoryModal}
        categoryId={editingCategoryId}
      />

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onRequestClose={closeConfirmModal}
        onConfirm={confirmDelete}
        title="Confirmer la Suppression"
        message="Êtes-vous sûr de vouloir supprimer cette catégorie ?"
      />
    </Container>
  );
};

export default CategoryList;
