import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FaHome, FaChartBar, FaUser, FaEnvelope, FaCog, FaBox } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import useLongPress from '../hooks/useLongPress';
import IOSMenu from './IOSMenu'; // Sous-menu personnalisé

const Bar = styled.div`
  height: 85px;
  background-color: white;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  padding:  10px;
  gap: 0px; /* Espacement fixe entre chaque icône */
`;

const IconWrapper = styled.div`
  position: relative;
  width: 5px; /* Largeur fixe pour chaque icône */
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ active, theme }) => (active ? theme.colors.primary : '#8e8e93')};
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
  flex-shrink: 0;

  &:focus,
  &:active {
    outline: none;
    background-color: transparent;
  }

  -webkit-tap-highlight-color: transparent;

  ${({ isPressing }) =>
    isPressing &&
    css`
      background-color: rgba(0, 122, 255, 0.1);
      border-radius: 50%;
      transform: scale(1.05);
      z-index: 10;
    `}
`;

const Label = styled.span`
  font-size: 12px;
  margin-top: 4px;
`;

const BottomBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuPosition, setMenuPosition] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(null);
  const menuRef = useRef(null);

  const handleCloseMenu = () => {
    setMenuPosition(null);
    setCurrentMenu(null);
  };

  const calculateMenuPosition = (buttonRect, menuHeight) => {
    let x = buttonRect.left + buttonRect.width / 2 - 75; // Centrer horizontalement
    let y = buttonRect.top - menuHeight - 10; // Placer au-dessus du bouton
  
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
  
    // Ajustement pour éviter que le menu dépasse les bords horizontaux
    if (x + 150 > windowWidth) {
      x = windowWidth - 150 - 10;
    }
    if (x < 10) {
      x = 10;
    }
  
    // Ajustement pour éviter que le menu dépasse en bas de l'écran
    if (y + menuHeight > windowHeight) {
      y = windowHeight - menuHeight - 10; // Ajuster pour le placer au-dessus
    }
  
    // Si le menu dépasse en haut, le placer en dessous du bouton
    if (y < 10) {
      y = buttonRect.bottom + 10;
    }
  
    return { x, y };
  };
  
  const handleLongPress = (button) => async (event) => {
    const buttonRect = event.target.getBoundingClientRect();
    setCurrentMenu(button);
    setMenuPosition({ x: buttonRect.left, y: buttonRect.top });

    setTimeout(() => {
      if (menuRef.current) {
        const menuHeight = menuRef.current.getBoundingClientRect().height;
        const adjustedPosition = calculateMenuPosition(buttonRect, menuHeight);
        setMenuPosition(adjustedPosition);
      }
    }, 0);
  };

  // Configuration des hooks useLongPress pour chaque bouton
  const dashboardPress = useLongPress(handleLongPress('Dashboard'), () => navigate('/dashboard'), { delay: 500 });
  const homePress = useLongPress(handleLongPress('Transactions'), () => navigate('/transactions'), { delay: 500 });
  const profilePress = useLongPress(handleLongPress('Profile'), () => navigate('/profile'), { delay: 500 });
  const stocksPress = useLongPress(handleLongPress('Stocks'), () => navigate('/stocks'), { delay: 500 });
  const settingsPress = useLongPress(handleLongPress('Settings'), () => navigate('/settings'), { delay: 500 });

  return (
    <>
      <Bar>
        <IconWrapper
          active={location.pathname === '/dashboard'}
          onClick={() => navigate('/dashboard')}
          ref={dashboardPress.ref}
          isPressing={dashboardPress.isPressing}
          aria-label="Accueil"
        >
          <FaHome size={24} />
          <Label>Accueil</Label>
        </IconWrapper>
        <IconWrapper
          active={location.pathname === '/transactions'}
          onClick={() => navigate('/transactions')}
          ref={homePress.ref}
          isPressing={homePress.isPressing}
          aria-label="Transactions"
        >
          <FaChartBar size={24} />
          <Label>Transactions</Label>
        </IconWrapper>
        <IconWrapper
          active={location.pathname === '/profile'}
          onClick={() => navigate('/profile')}
          ref={profilePress.ref}
          isPressing={profilePress.isPressing}
          aria-label="Profile"
        >
          <FaUser size={24} />
          <Label>Profil</Label>
        </IconWrapper>
        <IconWrapper
          active={location.pathname === '/products'}
          onClick={() => navigate('/products')}
          ref={stocksPress.ref}
          isPressing={stocksPress.isPressing}
          aria-label="Stocks"
        >
          <FaBox size={24} />
          <Label>Stocks</Label>
        </IconWrapper>
        <IconWrapper
          active={location.pathname === '/settings'}
          onClick={() => navigate('/settings')}
          ref={settingsPress.ref}
          isPressing={settingsPress.isPressing}
          aria-label="Settings"
        >
          <FaCog size={24} />
          <Label>Paramètres</Label>
        </IconWrapper>
      </Bar>
      {menuPosition && currentMenu && (
        <IOSMenu
          position={menuPosition}
          onClose={handleCloseMenu}
          menuType={currentMenu}
          menuRef={menuRef}
        />
      )}
    </>
  );
};

export default BottomBar;
