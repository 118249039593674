import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styled, { keyframes } from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { getProducts } from '../api/ProductService';
import { updateProduct } from '../api/ProductService'; // Importez la fonction pour mettre à jour le stock
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/AuthContext';

// Animations pour l'ouverture et la fermeture du modal
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
`;

// Overlay stylisé pour le modal
const StyledOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  transition: opacity 300ms ease-in-out;
  animation: ${fadeIn} 0.3s ease-out forwards;

  &.ReactModal__Overlay--before-close {
    animation: ${fadeOut} 0.3s ease-out forwards;
  }
`;

// Contenu stylisé du modal
const StyledContent = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  max-width: 80%;
  margin: auto;
  width: 50vh;
  text-align: center;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  position: relative;
  animation: ${fadeIn} 0.3s ease-out forwards;
`;

// Bouton de fermeture du modal
const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: #8e8e93;
  cursor: pointer;
  font-size: 24px;

  &:hover {
    color: #007AFF;
  }
`;

// Formulaire du modal
const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const FormTitle = styled.h2`
  color: #007AFF;
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
  text-align: left;
`;

const Label = styled.label`
  font-size: 16px;
  color: #1c1c1e;
  margin-bottom: 8px;
  display: block;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  background-color: #f2f2f7;
  border: 1px solid #d1d1d6;
  border-radius: 12px;
  color: #1c1c1e;
`;

const Select = styled.select`
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  background-color: #f2f2f7;
  border: 1px solid #d1d1d6;
  border-radius: 12px;
  color: #1c1c1e;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 25px;
`;

const Button = styled.button`
  flex: 1;
  padding: 14px 20px;
  border-radius: 10px;
  font-size: 16px;
  background-color: ${({ color }) => color || '#007AFF'};
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #005bb5;
  }
`;

const CancelButton = styled(Button)`
  background-color: #f2f2f7;
  color: #1c1c1e;

  &:hover {
    background-color: #e5e5ea;
  }
`;

// Composant TransactionModal
const TransactionModal = ({ isOpen, onRequestClose, onSubmit, transactionData, userId }) => {
  const [formData, setFormData] = useState({
    title: '',
    totalAmount: '',
    quantity: '1',
    paymentType: '',
    status: '',
    commissionAmount: '',
    product: '',
  });

  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const { auth } = useAuth(); 
  const { user } = auth || {};
  const authorizedCategories = user?.authorizedCategories || [];

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getProducts();
        console.log('Données récupérées:', data);
    
        const productsList = data.member || []; 
    
        if (user.roles.includes('ROLE_ADMIN')) {
          setProducts(productsList);
        } else {
          const authorizedProducts = productsList.filter(product => {
            if (!product.category || !product.category["@id"]) {
              console.warn(`Produit ${product.name} sans catégorie ou ID de catégorie`);
              return false; 
            }
    
            const extractedCategoryId = product.category["@id"].split('/').pop(); 
            return authorizedCategories.some(category => 
              String(category.id) === String(extractedCategoryId)
            );
          });
    
          setProducts(authorizedProducts); 
        }
    
        setLoadingProducts(false);
      } catch (error) {
        console.error('Erreur lors du chargement des produits:', error);
        toast.error('Erreur lors du chargement des produits');
        setLoadingProducts(false);
      }
    };
    
    fetchProducts();
  }, [authorizedCategories, user]);

  useEffect(() => {
    if (transactionData) {
      setFormData({
        title: transactionData.title || '',
        totalAmount: transactionData.totalAmount || '',
        quantity: transactionData.quantity || '1',
        paymentType: transactionData.paymentType || '',
        status: transactionData.status || '',
        commissionAmount: transactionData.commissionAmount || '',
        product: transactionData.product ? String(transactionData.product.id) : '',
      });
    } else {
      setFormData({
        title: '',
        totalAmount: '',
        quantity: '1',
        paymentType: 'Cash',
        status: '',
        commissionAmount: '',
        product: '',
      });
    }
  }, [transactionData]);

  useEffect(() => {
    if (formData.product) {
      const selectedProduct = products.find((product) => product.id === parseInt(formData.product, 10));

      if (selectedProduct) {
        const totalAmount = parseFloat(selectedProduct.price) * parseInt(formData.quantity, 10);
        const commission = parseFloat(selectedProduct.commissionAmount) * parseInt(formData.quantity, 10);

        setFormData((prevData) => ({
          ...prevData,
          totalAmount: totalAmount.toFixed(2),
          commissionAmount: commission.toFixed(2),
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          totalAmount: '',
          commissionAmount: '',
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        totalAmount: '',
        commissionAmount: '',
      }));
    }
  }, [formData.product, formData.quantity, products]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!userId) {
      toast.error('Utilisateur non identifié.');
      return;
    }
  
    if (!formData.product) {
      toast.error('Veuillez sélectionner un produit.');
      return;
    }
  
    const selectedProduct = products.find((product) => product.id === parseInt(formData.product, 10));
    
    if (selectedProduct) {
      const requestedQuantity = parseInt(formData.quantity, 10);
      const currentStock = parseInt(selectedProduct.stockQuantity, 10);
  
      if (requestedQuantity > currentStock) {
        toast.error('La quantité demandée dépasse le stock disponible.');
        return;
      }
  
      // Vérifiez si c'est une modification ou une création
      if (transactionData) {
        // Modification de la transaction
        const requestedTransactionQuantity = parseInt(transactionData.quantity, 10);
        
        // Réapprovisionnez le stock pour la quantité de transaction existante
        const updatedStockQuantity = currentStock - requestedQuantity + requestedTransactionQuantity;
  
        // Créez un objet de mise à jour pour le produit
        const productUpdatePayload = {
          stockQuantity: updatedStockQuantity.toString(),
        };
  
        try {
          await updateProduct(selectedProduct.id, productUpdatePayload);
        } catch (error) {
          console.error('Erreur lors de la mise à jour du stock :', error);
          toast.error('Erreur lors de la mise à jour du stock.');
          return;
        }
      } else {
        // Création d'une nouvelle transaction
        const updatedStockQuantity = currentStock - requestedQuantity;
  
        // Créez un objet de mise à jour pour le produit
        const productUpdatePayload = {
          stockQuantity: updatedStockQuantity.toString(),
        };
  
        try {
          await updateProduct(selectedProduct.id, productUpdatePayload);
        } catch (error) {
          console.error('Erreur lors de la mise à jour du stock :', error);
          toast.error('Erreur lors de la mise à jour du stock.');
          return;
        }
      }
    } else {
      toast.error('Produit introuvable.');
      return;
    }
  
    const transactionPayload = {
      ...formData,
      user: `/api/users/${userId}`,
      product: `/api/products/${formData.product}`,
    };
  
    console.log('TransactionPayload:', transactionPayload);
  
    try {
      await onSubmit(transactionPayload);
      toast.success(`Transaction ${transactionData ? 'modifiée' : 'créée'} avec succès.`);
      onRequestClose();
    } catch (error) {
      console.error('Erreur lors de la soumission de la transaction :', error);
      toast.error('Erreur lors de la soumission de la transaction.');
    }
  };
  

  const getCommissionAmount = (amount) => (isNaN(amount) ? 0 : amount);



  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      contentElement={(props, children) => <StyledContent {...props}>{children}</StyledContent>}
      overlayElement={(props, contentElement) => <StyledOverlay {...props}>{contentElement}</StyledOverlay>}
    >
      <CloseButton onClick={onRequestClose} aria-label="Fermer">
        <FaTimes />
      </CloseButton>

      <Form onSubmit={handleSubmit}>
        <FormTitle>{transactionData ? 'Modifier la Transaction' : 'Ajouter une Transaction'}</FormTitle>

        {/* Champ Titre */}
        <FormGroup>
          <Label htmlFor="title">Titre</Label>
          <Input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </FormGroup>

        {/* Champ Produit */}
        <FormGroup>
          <Label htmlFor="product">Produit</Label>
          <Select
            id="product"
            name="product"
            value={formData.product}
            onChange={handleChange}
            required
            disabled={loadingProducts}
          >
            <option value="">Sélectionner un produit</option>
            {products.map((product) => (
              <option key={product.id} value={String(product.id)}>
                {product.name} - Prix: {product.price} € - Commission: {getCommissionAmount(product.commissionAmount)} € - Stock: {product.stockQuantity}
              </option>
            ))}
          </Select>
        </FormGroup>

        {/* Champ Quantité */}
        <FormGroup>
          <Label htmlFor="quantity">Quantité</Label>
          <Input
            type="number"
            id="quantity"
            name="quantity"
            value={formData.quantity}
            onChange={handleChange}
            required
            min="1"
          />
        </FormGroup>

        {/* Champ Montant Total */}
        <FormGroup>
          <Label htmlFor="totalAmount">Montant Total (€)</Label>
          <Input
            type="number"
            id="totalAmount"
            name="totalAmount"
            value={formData.totalAmount}
            readOnly
          />
        </FormGroup>

        {/* Champ Type de Paiement */}
        <FormGroup>
          <Label htmlFor="paymentType">Type de Paiement</Label>
          <Select
            id="paymentType"
            name="paymentType"
            value={formData.paymentType}
            onChange={handleChange}
            required
          >
            <option value="">Sélectionner</option>
            <option value="Card">Carte</option>
            <option value="Cash">Cash</option>
            <option value="Transfer">Virement</option>
          </Select>
        </FormGroup>

        {/* Champ Statut */}
        <FormGroup>
          <Label htmlFor="status">Statut</Label>
          <Select
            id="status"
            name="status"
            value={formData.status}
            onChange={handleChange}
            required
          >
            <option value="">Sélectionner</option>
            <option value="Pending">En attente</option>
            <option value="Completed">Terminé</option>
            <option value="Cancelled">Annulé</option>
          </Select>
        </FormGroup>

        {/* Champ Montant de Commission */}
        <FormGroup>
          <Label htmlFor="commissionAmount">Montant de Commission (€)</Label>
          <Input
            type="number"
            id="commissionAmount"
            name="commissionAmount"
            value={formData.commissionAmount}
            readOnly
          />
        </FormGroup>

        {/* Groupe de Boutons */}
        <ButtonGroup>
          <CancelButton type="button" onClick={onRequestClose}>
            Annuler
          </CancelButton>
          <Button type="submit">
            {transactionData ? 'Modifier' : 'Créer'}
          </Button>
        </ButtonGroup>
      </Form>
    </Modal>
  );
};

TransactionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  transactionData: PropTypes.object,
  userId: PropTypes.number.isRequired,
};

export default TransactionModal;
