import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styled, { keyframes } from 'styled-components';
import { getCategory, createCategory, updateCategory } from '../api/CategoryService';
import { toast } from 'react-toastify';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';

// Animation d'apparition et de disparition
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
`;

// Overlay styled comme iOS avec des effets subtils de transparence et d'ombrage
const StyledOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  transition: opacity 300ms ease-in-out;
  animation: ${fadeIn} 0.3s ease-out forwards;

  &.ReactModal__Overlay--before-close {
    animation: ${fadeOut} 0.3s ease-out forwards;
  }
`;

// Contenu du modal avec un design plus "iOS-like"
const StyledContent = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  height: auto;
  max-height: 80vh;
  margin: auto;
  text-align: center;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  position: relative;
  animation: ${fadeIn} 0.3s ease-out forwards;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;

  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;

// Bouton de fermeture en haut à droite avec le style d'iOS
const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: #8e8e93;
  cursor: pointer;
  font-size: 24px;

  &:hover {
    color: #007AFF;
  }
`;

// Formulaire stylisé dans l'esprit minimaliste d'iOS
const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const FormTitle = styled.h2`
  color: #007AFF;
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
`;

// Champs de formulaire avec des bords arrondis et une animation subtile sur le focus
const FormGroup = styled.div`
  margin-bottom: 15px;
  text-align: left;
`;

const Label = styled.label`
  font-size: 16px;
  color: #1c1c1e;
  margin-bottom: 8px;
  display: block;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  background-color: #f2f2f7;
  border: 1px solid #d1d1d6;
  border-radius: 12px;
  color: #1c1c1e;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;

  &:focus {
    outline: none;
    border-color: #007AFF;
    box-shadow: 0 0 8px rgba(0, 122, 255, 0.2);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 25px;
`;

const Button = styled.button`
  flex: 1;
  padding: 14px 20px;
  border-radius: 10px;
  font-size: 16px;
  background-color: ${({ color }) => color || '#007AFF'};
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    background-color: ${({ color }) => color ? '#005BB5' : '#005BB5'};
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const CancelButton = styled(Button)`
  background-color: #f2f2f7;
  color: #1c1c1e;

  &:hover {
    background-color: #e5e5ea;
  }
`;

const CategoryModal = ({ isOpen, onRequestClose, categoryId }) => {
  const isEditing = Boolean(categoryId);

  const [formData, setFormData] = useState({
    name: '',
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEditing && isOpen) {
      fetchCategory(categoryId);
    } else if (!isEditing && isOpen) {
      resetForm();
    }
  }, [isEditing, isOpen]);

  const resetForm = () => {
    setFormData({
      name: '',
    });
  };

  const fetchCategory = async (id) => {
    setLoading(true);
    try {
      const category = await getCategory(id);
      setFormData({
        name: category.name || '',
      });
    } catch (error) {
      toast.error('Erreur lors de la récupération de la catégorie.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name) {
      toast.error('Le nom de la catégorie est obligatoire.');
      return;
    }

    const categoryPayload = {
      name: formData.name.trim(),
    };

    try {
      setLoading(true);
      if (isEditing) {
        await updateCategory(categoryId, categoryPayload);
        toast.success('Catégorie mise à jour avec succès.');
      } else {
        await createCategory(categoryPayload);
        toast.success('Catégorie créée avec succès.');
      }
      onRequestClose();
    } catch (error) {
      toast.error('Erreur lors de la soumission de la catégorie.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeTimeoutMS={300}
      ariaHideApp={false}
      contentLabel={isEditing ? 'Modifier Catégorie' : 'Ajouter Catégorie'}
      contentElement={(props, children) => <StyledContent {...props}>{children}</StyledContent>}
      overlayElement={(props, contentElement) => <StyledOverlay {...props}>{contentElement}</StyledOverlay>}
    >
      <CloseButton onClick={onRequestClose} aria-label="Fermer">
        <FaTimes />
      </CloseButton>

      <Form onSubmit={handleSubmit}>
        <FormTitle>{isEditing ? 'Modifier Catégorie' : 'Ajouter Nouvelle Catégorie'}</FormTitle>

        <FormGroup>
          <Label htmlFor="name">Nom de la Catégorie</Label>
          <Input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <ButtonGroup>
          <CancelButton type="button" onClick={onRequestClose}>
            Annuler
          </CancelButton>
          <Button type="submit" disabled={loading}>
            {isEditing ? 'Mettre à Jour' : 'Créer'}
          </Button>
        </ButtonGroup>
      </Form>
    </Modal>
  );
};

CategoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  categoryId: PropTypes.number,
};

export default CategoryModal;
