// src/theme.js
export const iosTheme = {
    colors: {
      background: '#F2F2F7',
      primary: '#007AFF', // Bleu iOS
      secondary: '#5AC8FA', // Bleu clair
      text: '#1C1C1E',
      subText: '#8E8E93',
      inputBackground: '#FFFFFF',
      border: '#C7C7CC',
      cardBackground: '#FFFFFF',
      buttonBackground: '#007AFF',
      buttonText: '#FFFFFF',
      deleteButton: '#FF3B30',
      modifyButton: '#34C759',
      detailsButton: '#FF9500',
      addButton: '#007AFF',
      shadow: 'rgba(0, 0, 0, 0.1)',
      modalBackground: 'rgba(0, 0, 0, 0.3)',
      emptyStateText: '#8E8E93',
    },
    fonts: {
      regular: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
      bold: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
    },
    borderRadius: '12px',
  };
  