import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import  GlobalStyle  from './GlobalStyle'; // Ajustez ces chemins si nécessaire
import { AuthProvider, useAuth } from './contexts/AuthContext'; // Ajustez le chemin
import LoginPage from './pages/LoginPage'; // Ajustez le chemin
import Dashboard from './pages/Dashboard'; // Ajustez le chemin
import PrivateRoute from './components/PrivateRoute'; // Ajustez le chemin
import ProductList from './pages/ProductList'; // Ajustez le chemin
import UserList from './pages/UserList'; // Ajustez le chemin
import CategoryList from './pages/CategoryList'; // Ajustez le chemin
import TransactionList from './pages/TransactionList'; // Ajustez le chemin
import ProductForm from './pages/ProductForm'; // Ajustez le chemin
import Profile from './pages/Profile'; // Ajustez le chemin
import Settings from './pages/Settings'; // Ajustez le chemin
import LockScreen from './components/LockScreen'; // Ajustez le chemin
import Layout from './components/Layout'; // Ajustez le chemin
import { IonSpinner } from '@ionic/react'; // Si vous utilisez IonSpinner
import CreditList from './pages/CreditList';

const theme = {
  colors: {
    primary: '#007AFF',
    background: '#F2F2F7',
    text: '#1C1C1E',
    inputBackground: '#FFFFFF',
    border: '#C7C7CC',
  },
};

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 10%;
`;

function App() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isLocked, setIsLocked] = useState(false); 

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleUnlock = () => {
    setIsLocked(false);
    sessionStorage.setItem('unlocked', 'true'); 
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <AuthProvider>
          <AppContainer>
            {isLocked ? (
              <LockScreen onUnlock={handleUnlock} />
            ) : (
              <Layout isMenuOpen={isMenuOpen} toggleMenu={toggleMenu}>
                <AuthenticatedRoutes />
              </Layout>
            )}
          </AppContainer>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

const AuthenticatedRoutes = () => {
  const { auth } = useAuth(); // Utiliser le contexte d'authentification ici

  return (
    <Routes>
      <Route path="/login" element={auth.token ? <Navigate to="/dashboard" /> : <LoginPage />} />
      <Route path="/" element={<Navigate to={auth.token ? "/dashboard" : "/login"} />} />
      <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
      <Route path="/products" element={<PrivateRoute allowedRoles={['ROLE_ADMIN', 'ROLE_USER']}><ProductList /></PrivateRoute>} />
      <Route path="/users" element={<PrivateRoute allowedRoles={['ROLE_ADMIN']}><UserList /></PrivateRoute>} />
      <Route path="/categories" element={<PrivateRoute allowedRoles={['ROLE_ADMIN']}><CategoryList /></PrivateRoute>} />
      <Route path="/transactions" element={<PrivateRoute allowedRoles={['ROLE_ADMIN', 'ROLE_USER']}><TransactionList /></PrivateRoute>} />
      <Route path="/credit" element={<PrivateRoute allowedRoles={['ROLE_ADMIN', 'ROLE_USER']}><CreditList /></PrivateRoute>} />
      <Route path="/product-form" element={<PrivateRoute allowedRoles={['ROLE_ADMIN']}><ProductForm /></PrivateRoute>} />
      <Route path="/product-form/:id" element={<PrivateRoute allowedRoles={['ROLE_ADMIN']}><ProductForm /></PrivateRoute>} />
      <Route path="/profile" element={<PrivateRoute allowedRoles={['ROLE_ADMIN', 'ROLE_USER']}><Profile /></PrivateRoute>} />
      <Route path="/settings" element={<PrivateRoute allowedRoles={['ROLE_USER']}><Settings /></PrivateRoute>} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default App;
