import React from 'react';
import styled from 'styled-components';

const StyledAvatar = styled.div`
  width: 100px;
  height: 100px;
  background-color: #C7C7CC;
  border-radius: 50%;
  margin-bottom: 20px;
`;

const Avatar = () => {
  return <StyledAvatar />;
};

export default Avatar;
